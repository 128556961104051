import NavBarAdmin from "../../components/Admin/Layout/NavBarAdmin";
import PartnerAdminTable from "../../components/Admin/Table/PartnerAdminTable";
import { useEffect } from "react";
import useModal from "../../hooks/useModal";
import CreateUser from "../../components/Admin/Modales/CreateUser";
import SearchBar from "../../components/Admin/Services/SearchBar";
import { usePartnerStore } from "../../stores/admin/users";
import { useServiceStore } from "../../stores/admin/service";
import { useParams } from "react-router-dom";
import { getAllPartners } from "../../services/Api/adminService";
import { ToastContainer } from "react-toastify";
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";

const AdminPartner = () => {
  // Store
  const { usersPartner, setUsersPartner } = usePartnerStore();
  const { update, searchTerm } = useServiceStore();
  const { idPartner } = useParams();
  // Hook
  const { modalContent, isModalOpen, openModal, closeModal } = useModal();

  // Function to display users partner filtered or not
  useEffect(() => {
    if (searchTerm !== "") {
      const filteredRequests = usersPartner.filter(
        (item: any) =>
          item?.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item?.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item?.eMail.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setUsersPartner(filteredRequests);
    } else {
      getAllPartners(Number(idPartner))
        .then((data) => setUsersPartner(data))
        .catch((error) => console.log(error));
    }
  }, [searchTerm, update]);

  return (
    <div className="w-screen h-screen bg-white flex flex-col justify-start items-start overflow-hidden ">
      {isModalOpen ? modalContent : null}
      <Header />
      <div className="w-full flex justify-start items-start">
        <ToastContainer position="top-center" />
        <NavBarAdmin />
        <div className="flex flex-col justify-start items-center w-11/12 h-screen">
          <div className="w-11/12 flex justify-start sm:justify-between items-center 3xl:my-3">
            <h2 className="text-nxm-darkGray font-light 2xl:text-3xl text-xl font-K2D py-2 ">
              Liste des collaborateurs :
            </h2>
            <button
              className="hidden xl:w-44 xl:h-10 xl:flex mt-5 xl:mb-2 items-center justify-center rounded-md border-[1px] border-yellow-trio p-2 bg-yellow-trio font-K2D text-md shadow-xl"
              onClick={() => openModal(<CreateUser closeModal={closeModal} />)}
            >
              + Créer un utilisateur
            </button>
          </div>
          <div className=" w-11/12 flex justify-between items-center">
            <div className="2xl:my-5 my-1 w-auto">
              <SearchBar />
            </div>
          </div>
          <div className="flex h-[90%] md:h-[75%] w-11/12 mb-20">
            <PartnerAdminTable />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AdminPartner;
