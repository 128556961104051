import { useEffect } from "react";
import ModalForgotPassword from "../../components/Layout/ModalForgotPassword";
import { useTokenContext } from "../../context/tokenContext";
import { NavLink, useNavigate } from "react-router-dom";
import {
  login,
  logWithGoogle,
  logWithOffice,
} from "../../services/Api/loginService";
import Cookies from "js-cookie";
import Eye from "../../assets/icons/eye.svg";
import { redirection } from "../../services/Utils/functionService";
import Modal2fa from "../../components/Layout/Modal2fa";
import { useConnexionStore } from "../../stores/Layout/connexion";
import { initSocket } from "../../services/Utils/socketIo";
import { getNotifications } from "../../services/Api/userService";
import { useNotifsStore } from "../../stores/Utils/notifs";
import { useMfaStore } from "../../stores/Utils/mfa";
import ModalMfa from "../../components/Layout/ModalMfa";
import logo from "../../assets/logos/logoX.png";
import microsoft from "../../assets/logos/microsoft.png";
import user from "../../assets/icons/user.svg";
import { ToastContainer } from "react-toastify";
import VerifyEmailRegister from "../../components/Layout/VerifyEmailRegister";
import { GoogleLogin } from "@react-oauth/google";
import { showToastError } from "../../services/Toasts/toasts";
import { useMsal } from "@azure/msal-react";

const Connexion = () => {
  // Store
  const {
    setDisplayPopUpCookie,
    email,
    setEmail,
    password,
    setPassword,
    setShowModalForgotPassword,
    passwordInputType,
    setPasswordInputType,
    setShowModal2fa,
    setShowModalVerifyEmail,
  } = useConnexionStore();
  const { setNotifs } = useNotifsStore();
  const { setShowModalCodeMFA } = useMfaStore();
  // Hooks
  const navigate = useNavigate();

  // Cookie
  const consentementCookies = Cookies.get("consentementCookies");

  // Context
  const {
    setToken,
    setFirstname,
    setLastname,
    setEmailUser,
    setUserId,
    setMfa,
    setUtc,
    setUtp,
    setIsAdmin,
    setIsSuperAdmin,
    setAccountType,
  } = useTokenContext();

  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setPasswordInputType(
      passwordInputType === "password" ? "text" : "password"
    );
  };

  // Display pop up cookie
  useEffect(() => {
    if (!consentementCookies) {
      setDisplayPopUpCookie(true);
    } else {
      setDisplayPopUpCookie(false);
    }
  }, [consentementCookies]);

  // Handle key down for enter key to login
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  // Function to save data and redirect
  const saveAndRedirect = (response: any) => {
    setFirstname(response?.data?.firstName);
    localStorage.setItem("firstName", response?.data?.firstName);
    setLastname(response?.data?.lastName);
    localStorage.setItem("lastName", response?.data?.lastName);
    setEmailUser(response?.data?.eMail);
    setUserId(response?.data?.id);
    setMfa(response?.data?.mfa);
    localStorage.setItem("mfa", response?.data?.mfa);
    setUtc(response?.data?.utc);
    localStorage.setItem("utc", response?.data?.utc);
    setUtp(response?.data?.utp);
    localStorage.setItem("utp", response?.data?.utp);
    setIsSuperAdmin(response?.data?.isSuperAdmin);
    localStorage.setItem("isSuperAdmin", response?.data?.isSuperAdmin);
    setIsAdmin(response?.data?.isAdmin);
    localStorage.setItem("isAdmin", response?.data?.isAdmin);
    setAccountType(response?.data?.accountType);
    localStorage.setItem("accountType", response?.data?.accountType);
    setToken(1);
    if (response?.data?.lastConnection === null) {
      localStorage.setItem("lastConnection", "null");
    }
    // Socket
    if (response?.data?.id) {
      initSocket(response?.data?.id);
      setEmail("");
      setPassword("");
      // Get notifications
      getNotifications()
        .then((response) => setNotifs(response))
        .catch((error) => console.log(error));
    }

    redirection(location, navigate, response?.data?.isSuperAdmin);
  };

  // function for login
  const handleLogin = async () => {
    try {
      const response = await login(email, password);
      saveAndRedirect(response);
    } catch (error: any) {
      if (error.message) {
        setUtc(0);
        setUtp(0);
        setIsAdmin(0);
        setIsSuperAdmin(0);
        setFirstname("");
        localStorage.clear();
      }
      if (
        error.message &&
        typeof error.message === "string" &&
        error.message.trim() === "eMail 2FA sent"
      ) {
        setShowModal2fa(true);
      } else if (
        error.message &&
        typeof error.message === "string" &&
        error.message.trim() === "2faTotpRequired"
      ) {
        setShowModalCodeMFA(true);
      } else if (
        error.message &&
        typeof error.message === "string" &&
        error.message.trim() === "Mot de passe incorrect"
      ) {
        showToastError("Mot de passe incorrect");
      } else if (
        error.message &&
        typeof error.message === "string" &&
        error.message.trim() === "googleAccount"
      ) {
        showToastError("Merci de vous connecter avec Google.");
      } else if (
        error.message &&
        typeof error.message === "string" &&
        error.message.trim() === "officeAccount"
      ) {
        showToastError("Merci de vous connecter avec Office.");
      } else if (
        error.message &&
        typeof error.message === "string" &&
        error.message.trim() === "accountNotCreated"
      ) {
        showToastError("Merci de créer votre compte avant de vous connecter.");
      }
    }
  };

  // Login with google
  const loginWithGoogle = (credentialResponse: any) => {
    logWithGoogle(credentialResponse.credential)
      .then((response) => {
        saveAndRedirect(response);
        setToken(1);
      })
      .catch((error) => {
        if (
          error.message &&
          typeof error.message === "string" &&
          error.message.trim() === "User not invited"
        ) {
          setUtc(0);
          setUtp(0);
          setIsAdmin(0);
          setIsSuperAdmin(0);
          setFirstname("");
          localStorage.clear();
          showToastError("Vous n'avez pas été invité a rejoindre Nexum.");
        } else if (
          error.message &&
          typeof error.message === "string" &&
          error.message.trim() === "User exist but account not linked"
        ) {
          showToastError(
            "Vous devez lier votre compte Google à votre compte Nexum."
          );
        } else {
          showToastError("Une erreur est survenue lors de la connexion.");
        }
      });
  };

  const loginErrror = () => {
    showToastError("Une erreur est survenue lors de la connexion.");
  };

  const { instance } = useMsal();

  // Login with office
  const handleLoginOffice = () => {
    instance
      .loginPopup({
        scopes: ["user.read"],
      })
      .then((response) => {
        logWithOffice(response.idToken, response.accessToken)
          .then((response) => {
            saveAndRedirect(response);
            setToken(1);
          })
          .catch((error) => {
            if (
              error.message &&
              typeof error.message === "string" &&
              error.message.trim() === "User not invited"
            ) {
              setUtc(0);
              setUtp(0);
              setIsAdmin(0);
              setIsSuperAdmin(0);
              setFirstname("");
              localStorage.clear();
              showToastError("Vous n'avez pas été invité a rejoindre Nexum.");
            } else if (
              error.message &&
              typeof error.message === "string" &&
              error.message.trim() === "User exist but account not linked"
            ) {
              showToastError(
                "Vous devez lier votre compte office à votre compte Nexum."
              );
            } else {
              showToastError("Une erreur est survenue lors de la connexion.");
            }
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="w-screen h-screen flex ">
      <ToastContainer position="top-center" />
      <div className="hidden md:block absolute top-2 left-14">
        <img src={logo} alt="logo Nexum" className="w-10 h-14" />
      </div>
      <div className="w-full h-screen flex items-center justify-center flex-col bg-white">
        <div className="3xl:w-2/12 md:w-4/12 w-9/12 flex flex-col items-center justify-around bg-dark-lavender rounded-md shadow-xl relative z-20">
          <div className="w-16 h-16 border-[1px] border-yellow-trio rounded-md bg-yellow-trio absolute -top-8 -left-8 z-10 shadow-xl "></div>
          <h2 className="text-white font-bold 3xl:text-3xl text-xl font-K2D 2xl:my-5 mt-4 ">
            SE CONNECTER
          </h2>
          <hr className="border-[1px] border-yellow-trio w-8/12 xl:my-5 my-2" />
          <div className="flex justify-start w-8/12">
            <label
              htmlFor="email"
              className="text-white text-md 3xl:text-xl font-BarlowCondensed"
            >
              Identifiant :
            </label>
          </div>
          <input
            type="email"
            className="border-b-[1px] w-8/12 h-10 rounded-md bg-white focus:outline-none mt-1 shadow-xl pl-1"
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            onChange={(e) => setEmail(e.target.value)}
            minLength={10}
            maxLength={200}
            value={email}
            name="email"
            id="email"
            required
          />
          <div className="flex justify-start w-8/12 2xl:mt-8 mt-4">
            <label
              htmlFor="password"
              className="text-white text-md 3xl:text-xl font-BarlowCondensed"
            >
              Mot de passe :
            </label>
          </div>
          <div className="relative flex w-8/12 h-10">
            <input
              type={passwordInputType}
              className="border-b-[1px] bg-white rounded-md w-full focus:outline-none h-10 mt-1 shadow-xl pl-1"
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              onChange={(e) => setPassword(e.target.value)}
              name="password"
              value={password}
              id="password"
              onKeyDown={handleKeyDown}
              required
            />
            <button
              className="absolute right-2 top-3"
              onClick={togglePasswordVisibility}
            >
              <img src={Eye} alt="oeil" />
            </button>
          </div>
          <button
            type="button"
            className="underline text-white text-xs sm:text-base font-BarlowCondensed 2xl:my-8 my-4"
            onClick={() => setShowModalForgotPassword(true)}
          >
            Mot de passe oublié ?
          </button>
          <ModalForgotPassword />

          <button
            type="button"
            onClick={handleLogin}
            tabIndex={0}
            onFocus={(event) => (event.target.style.outline = "blue")}
            className="font-BarlowCondensed text-xl w-28 border-2 border-yellow-trio sm:w-32 sm:mx-10 rounded-md 2xl:h-10 h-8 bg-yellow-trio text-white 2xl:mb-5 mb-2 shadow-xl "
          >
            Connexion
          </button>

          <hr className="border-[1px] border-yellow-trio w-8/12 my-5" />

          <GoogleLogin onSuccess={loginWithGoogle} onError={loginErrror} />

          <button
            className="border-b-[1px] border-[#d2e3fc] bg-[#F8FAFE] text-[#3c4043] font-roboto rounded-sm w-[222px] text-[15px] focus:outline-none h-10 shadow-xl flex justify-center items-center mt-4 "
            onClick={handleLoginOffice}
          >
            <img
              src={microsoft}
              alt="logo microsoft"
              className="h-5 w-5 mr-4"
            />
            <p>Se connecter avec Office</p>
          </button>

          <button
            className="border-b-[1px] border-[#d2e3fc] bg-[#F8FAFE] text-[#3c4043] font-roboto rounded-sm w-[222px] text-[15px] focus:outline-none h-10 shadow-xl flex justify-center items-center mt-4 "
            onClick={() => setShowModalVerifyEmail(true)}
          >
            <img src={user} alt="logo user" className="h-5 w-5 mr-4" />
            <p>Créer un compte</p>
          </button>

          <VerifyEmailRegister />

          <Modal2fa />
          <ModalMfa />
          <div className="font-BarlowCondensed flex flex-col justify-end items-center 2xl:mt-10 mt-5">
            <div className="flex justify-center items-center text-white">
              <NavLink
                to="/legalnotice"
                className="underline text-sm text-white mr-1"
              >
                Mentions légales
              </NavLink>
              {" - "}
              <NavLink
                to="/cookiesnotice"
                className="underline text-sm text-white mx-1"
              >
                Cookies
              </NavLink>
              {" - "}
              <NavLink to="/cgu" className="underline text-sm text-white ml-1">
                CGU
              </NavLink>
            </div>
            <p className="font-extralight text-sm text-center pb-4 text-white">
              © 2024 by TRIONODE.
            </p>
          </div>
          <div className="w-16 h-16 border-[1px] border-yellow-trio rounded-md bg-yellow-trio absolute -bottom-8 -right-8 z-10 shadow-xl "></div>
        </div>
      </div>
    </div>
  );
};

export default Connexion;
