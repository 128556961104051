import { create } from "zustand";
import { INotif } from "../../interfaces/notifsInterface";

type NotifsState = {
    notifs: INotif[]; 
    setNotifs: (notifs: INotif[] | ((prevNotifs: INotif[]) => INotif[])) => void;
    addNotif: (notif: any) => void;

    showNotifs: boolean;
    setShowNotifs: (showNotifs: boolean) => void;

    hasOpenedNotifs: boolean;
    setHasOpenedNotifs: (hasOpened: boolean) => void;

    unreadNotifCount: number;
    setUnreadNotifCount: (unreadNotifCount: number) => void

    showHelp: boolean;
    setShowHelp: (showHelp: boolean) => void;
};
  
  export const useNotifsStore = create<NotifsState>((set) => ({
    notifs: [],
    setNotifs: (notifs) =>
      set((state) => ({
        notifs: notifs instanceof Function ? notifs(state.notifs) : notifs,
      })),
    addNotif: (notif) => set((state) => ({ notifs: [...state.notifs, notif] })),
    
    showNotifs: false,
    setShowNotifs: (showNotifs) => set(() => ({ showNotifs: showNotifs })),

    hasOpenedNotifs: false,
    setHasOpenedNotifs: (hasOpened) => set(() => ({ hasOpenedNotifs: hasOpened })),

    unreadNotifCount: 0,
    setUnreadNotifCount: (unreadNotifCount) => set({unreadNotifCount: unreadNotifCount}),

    showHelp: false,
    setShowHelp: (showHelp) => set(() => ({ showHelp: showHelp })),
  }));
  