import { useEffect, useState } from "react";
import { getAllManagers, updateClientFile } from "../../../services/Api/adminService";
import { useParams } from "react-router-dom";
import { useServiceStore } from "../../../stores/admin/service";
import { showToastSuccess } from "../../../services/Toasts/toasts";

const UpdateClientFile = ({ id, closeModal, name, idManager }: any) => {
    const { idPartner } = useParams();
    const [responsables, setResponsables] = useState([]);
    const [selectedResponsable, setSelectedResponsable] = useState<number>(0)
    const [rename, setRename] = useState(name);
    const { update, setUpdate} = useServiceStore();
    useEffect(() => {
        getAllManagers(Number(idPartner))
          .then((data) => {
            setResponsables(data)
            const selected = data.find((responsable: any) => responsable.id === idManager)
            setSelectedResponsable(selected?.id)
        })
          .catch((error) => console.log(error));
      }, [id]);

    const handleUpdate = () => {
        updateClientFile(Number(idPartner), id, { name : rename, managerId: selectedResponsable })
            .then(() => {
                closeModal()
                setUpdate(!update)
                setRename('')
                showToastSuccess("Dossier modifié avec succès")
            })
            .catch((error) => console.log(error));
    }
      
    console.log(selectedResponsable)
    return (
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto md:w-2/4 my-6 mx-auto max-w-3xl min-w-[45%]">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="flex p-5 border-b border-solid border-slate-200 rounded-t">
                <h3 className="text-xl 2xl:text-3xl w-full font-semibold text-center text-nxm-darkGray font-K2D">
                  Modification d'un dossier client
                </h3>
              </div>
              {/*body*/}
              <div className="w-10/12 m-auto flex flex-col items-center justify-center">
                <div className="flex justify-center items-center w-full py-4">
                    <div className="flex flex-col w-1/2 mr-10">
                        <p className="font-BarlowCondensed">Nom du dossier :</p>
                        <input
                            type="text"
                            className="border-[1px] border-slate-200 w-full h-auto p-2 bg-transparent rounded-md"
                            value={rename}
                            onChange={(e) => setRename(e.target.value)}
                        />
                        </div>
                        <div className="flex flex-col w-1/2">
                            <p className="font-BarlowCondensed">Responsable :</p>
                            <select
                                className="w-full border-[1px] border-slate-200 h-10 bg-transparent rounded-md"
                                value={selectedResponsable}
                                onChange={(e) => setSelectedResponsable(parseInt(e.target.value))}
                            >
                                {responsables?.map((responsable: any) => (
                                    <option key={responsable?.id} value={responsable?.id}>
                                        {responsable?.fullName === " " ? responsable?.eMail : responsable?.fullName}
                                    </option>
                                ))}
                            </select>
                        </div>
                </div>
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-4 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="text-red-300 background-transparent hover:text-red-500 font-bold uppercase px-6 py-2 2xl:text-lg text-base  outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => closeModal()}
                >
                  Annuler
                </button>
                <button
                  className="text-white bg-gradient-to-r from-light-lavender via-light-lavender to-dark-lavender hover:bg-gradient-to-br font-medium rounded-lg 2xl:text-lg text-base  px-5 py-2.5 text-center me-2 uppercase  shadow hover:shadow-lg outline-none  ease-linear transition-all duration-150"
                  type="button"
                  onClick={handleUpdate}
                >
                  MODIFIER
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    );
}

export default UpdateClientFile