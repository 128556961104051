import { ToastContainer } from "react-toastify";
import logo from "../../assets/logos/logoX.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useCreateAccount } from "../../stores/Layout/createAccount";
import Eyes from "../../assets/icons/eye.svg";
import { showToastError, showToastSuccess } from "../../services/Toasts/toasts";
import {
  createAccount,
  createAccountWithGoogle,
  createAccountWithOffice,
} from "../../services/Api/userService";
import { GoogleLogin } from "@react-oauth/google";
import microsoft from "../../assets/logos/microsoft.png";
import { useMsal } from "@azure/msal-react";

const CreateAnAccount = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    phone,
    setPhone,
    title,
    setTitle,
    password,
    setPassword,
    verifyPassword,
    setVerifyPassword,
    passwordInputType,
    setPasswordInputType,
    acceptedCgu,
    setAcceptedCgu,
  } = useCreateAccount();
  const navigate = useNavigate();

  // Password visibility
  const togglePasswordVisibility = () => {
    setPasswordInputType(
      passwordInputType === "password" ? "text" : "password"
    );
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleCreateAccount();
    }
  };

  const handleCreateAccount = () => {
    if (!acceptedCgu) return showToastError("Vous devez accepter les CGU.");
    if (password !== verifyPassword)
      return showToastError("Les deux mots de passe doivent être identiques !");

    const data = {
      firstName,
      lastName,
      email,
      tel: phone,
      titre: title,
      password: verifyPassword,
    };
    if (token !== "") {
      createAccount(data, token)
        .then(() => {
          showToastSuccess("Votre compte à bien été créé.");
          navigate("/");
        })
        .catch((err: any) => {
          console.log(err);
          if (
            err.response.data ===
            "email in token does not correspond to email in query"
          ) {
            showToastError(
              "Vous n'avez pas été invité à rejoindre Nexum. Vérifiez l'email de l'invitation."
            );
          } else {
            showToastError("Une erreur est survenue.");
          }
        });
    }
  };

  const singInWithGoogle = (credentialResponse: any) => {
    createAccountWithGoogle(credentialResponse.credential, token)
      .then(() => {
        showToastSuccess("Votre compte a bien été créé.");
        navigate("/");
      })
      .catch((error) => {
        if (error.response.status === 500) {
          if (error.response.data === "email account not match") {
            showToastError(
              "Merci de vous connecter avec l'email de l'invitation."
            );
          } else {
            showToastError("Une erreur est survenue lors de la connexion.");
          }
        }
      });
  };

  const loginErrror = () => {
    showToastError("Une erreur est survenue lors de la connexion.");
  };

  const { instance } = useMsal();

  // Login with office
  const handleSingInOffice = () => {
    instance
      .loginPopup({
        scopes: ["user.read"],
      })
      .then((response) => {
        createAccountWithOffice(response.idToken, response.accessToken, token)
          .then(() => {
            showToastSuccess("Votre compte a bien été créé.");
            navigate("/");
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        if (error.response.status === 500) {
          if (error.response.data === "email account not match") {
            showToastError(
              "Merci de vous connecter avec l'email de l'invitation."
            );
          } else {
            showToastError("Une erreur est survenue lors de la connexion.");
          }
        } else {
          showToastError("Une erreur est survenue lors de la connexion.");
        }
      });
  };

  return (
    <div className="w-screen h-screen flex ">
      <ToastContainer position="top-center" />
      <div className="hidden lg:block absolute top-2 left-14">
        <img src={logo} alt="logo Nexum" className="w-10 h-14" />
      </div>

      <div className="w-full h-screen flex items-center justify-center flex-col bg-white">
        <div className="3xl:w-5/12 md:w-6/12 w-9/12 flex flex-col items-center justify-around bg-dark-lavender rounded-md shadow-xl relative z-50">
          <div className="w-16 h-16 border-[1px] border-yellow-trio rounded-md bg-yellow-trio absolute -top-8 -left-8 z-10 shadow-xl "></div>
          <h2 className="text-white font-bold 3xl:text-3xl text-xl font-K2D 2xl:my-5 mt-4 text-center">
            CREATION DE VOTRE COMPTE
          </h2>
          <p className="w-10/12 m-auto text-sm text-grey-trio">
            Vous avez été invité a rejoindre Nexum, la plateforme d'échange de
            documents merci de renseigner vos coordonnées personnels. <br />
            Votre mot de passe doit contenir au minimum 8 caractères, au maximum
            30, une majuscule, une minuscule, un chiffre et un caractère
            spécial.
          </p>
          <hr className="border-[1px] border-yellow-trio w-8/12 xl:my-5 my-2 font-BarlowCondensed" />
          <div className="w-10/12 flex flex-col justify-start items-center">
            <div className="w-full flex flex-col justify-center items-center xl:grid grid-cols-2 gap-5 gap-x-20">
              <div className="flex flex-col justify-start items-start">
                <p className="text-white">Prénom :</p>
                <input
                  type="text"
                  name="firstname"
                  id="firstname"
                  value={firstName}
                  required
                  onChange={(e) => setFirstName(e.target.value)}
                  className="border-b-[1px] w-full h-10 rounded-md bg-white focus:outline-none mt-1 shadow-xl pl-1"
                />
              </div>
              <div className="flex flex-col justify-start items-start">
                <p className="text-white">Nom :</p>
                <input
                  type="text"
                  name="lastname"
                  id="lastname"
                  value={lastName}
                  required
                  onChange={(e) => setLastName(e.target.value)}
                  className="border-b-[1px] w-full h-10 rounded-md bg-white focus:outline-none mt-1 shadow-xl pl-1"
                />
              </div>
              <div className="flex flex-col justify-start items-start">
                <p className="text-white">Email :</p>
                <div className="relative w-full">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    className="border-b-[1px] w-full h-10 rounded-md bg-white focus:outline-none mt-1 shadow-xl pl-1"
                  />
                </div>
              </div>
              <div className="flex flex-col justify-start items-start">
                <p className="text-white">Téléphone :</p>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="border-b-[1px] w-full h-10 rounded-md bg-white focus:outline-none mt-1 shadow-xl pl-1"
                />
              </div>
              <div className="flex flex-col justify-start items-start">
                <p className="text-white">Mot de passe :</p>
                <div className="relative w-full">
                  <input
                    type={passwordInputType}
                    name="password"
                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                    id="password"
                    value={password}
                    required
                    onChange={(e) => setPassword(e.target.value)}
                    className="border-b-[1px] w-full h-10 rounded-md bg-white focus:outline-none mt-1 shadow-xl pl-1"
                  />
                  <button
                    type="button"
                    className="absolute top-3 right-2"
                    onClick={togglePasswordVisibility}
                  >
                    <img src={Eyes} alt="oeil" />
                  </button>
                </div>
              </div>
              <div className="flex flex-col justify-start items-start">
                <div className="relative w-full">
                  <p className="text-white">Vérifié mot de passe :</p>
                  <input
                    type={passwordInputType}
                    name="verifypassword"
                    id="verifypassword"
                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                    value={verifyPassword}
                    required
                    onChange={(e) => setVerifyPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                    className="border-b-[1px] w-full h-10 rounded-md bg-white focus:outline-none mt-1 shadow-xl pl-1"
                  />
                  <button
                    type="button"
                    className="absolute top-9 right-2"
                    onClick={togglePasswordVisibility}
                  >
                    <img src={Eyes} alt="oeil" />
                  </button>
                </div>
              </div>
              <div className="flex flex-col justify-start items-start">
                <p className="text-white">Titre :</p>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="border-b-[1px] w-full h-10 rounded-md bg-white focus:outline-none mt-1 shadow-xl pl-1"
                />
              </div>
              <div className="flex justify-center items-center mt-4">
                <button
                  className="text-white mr-4"
                  onClick={() => navigate("/cgu")}
                >
                  Accepter les CGU :
                </button>
                <input
                  type="checkbox"
                  name="cgu"
                  id="cgu"
                  checked={acceptedCgu}
                  onChange={() => setAcceptedCgu(!acceptedCgu)}
                  required
                  className="h-10 rounded-md bg-white focus:outline-none shadow-xl"
                />
              </div>
              <div className="flex justify-center items-center"></div>
              <div className="flex justify-end items-center w-full gap-4 mb-5">
                <button
                  className="text-red-300 background-transparent hover:text-red-500 font-bold uppercase px-6 py-2 2xl:text-lg text-base  outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  onClick={() => navigate("/")}
                >
                  Annuler
                </button>
                <button
                  className="text-white bg-gradient-to-r from-light-lavender via-light-lavender to-dark-lavender hover:bg-gradient-to-br font-medium rounded-lg 2xl:text-lg text-base  px-5 py-2.5 text-center me-2 uppercase  shadow hover:shadow-lg outline-none  ease-linear transition-all duration-150"
                  onClick={handleCreateAccount}
                >
                  Créer
                </button>
              </div>
            </div>
            <hr className="border-[1px] border-yellow-trio w-9/12 xl:mb-5 my-2 font-BarlowCondensed" />
            <p className="w-12/12 m-auto text-sm text-grey-trio">
              En procédant à votre enregistrement via Google ou Office, vous
              acceptez automatiquement les Conditions Générales d'Utilisation
              (CGU).
            </p>
            <div className="flex justify-center items-center w-full gap-10 mb-5 mt-5">
              <GoogleLogin onSuccess={singInWithGoogle} onError={loginErrror} />

              <button
                className="border-b-[1px] border-[#d2e3fc] bg-[#F8FAFE] text-[#3c4043] font-roboto rounded-sm w-[222px] text-[15px] focus:outline-none h-10 shadow-xl flex justify-center items-center"
                onClick={handleSingInOffice}
              >
                <img
                  src={microsoft}
                  alt="logo microsoft"
                  className="h-5 w-5 mr-4"
                />
                <p>S'enregistrer avec Office</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAnAccount;
