import { createContext, useContext, useEffect, useState } from "react";
import { TokenContextValue } from "../interfaces/context";
import { getUserInformation } from "../services/Api/loginService";

const TokenContext = createContext<TokenContextValue | null>(null);

export const TokenProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // state for save user informations
  const [token, setToken] = useState<number>(
    parseInt(localStorage.getItem("token") || "0", 10)
  );
  const [firstname, setFirstname] = useState<string>(
    localStorage.getItem("firstName") || ""
  );
  const [lastname, setLastname] = useState<string>(
    localStorage.getItem("lastName") || ""
  );
  const [emailUser, setEmailUser] = useState<string>("");
  const [roleId, setRoleId] = useState<number>(
    parseInt(localStorage.getItem("role") || "0", 10)
  );
  const [userId, setUserId] = useState<number>(0);
  const [mfa, setMfa] = useState<boolean>(
    localStorage.getItem("mfa") === "true" ? true : false
  );
  const [utc, setUtc] = useState<number>(
    parseInt(localStorage.getItem("utc") || "0", 10)
  );
  const [utp, setUtp] = useState<number>(
    parseInt(localStorage.getItem("utp") || "0", 10)
  );
  const [isAdmin, setIsAdmin] = useState<number>(
    parseInt(localStorage.getItem("isAdmin") || "0", 10)
  );
  const [isSuperAdmin, setIsSuperAdmin] = useState<number>(
    parseInt(localStorage.getItem("isSuperAdmin") || "0", 10)
  );
  const [accountType, setAccountType] = useState<string>(
    localStorage.getItem("accountType") || ""
  );

  useEffect(() => {
    if (token === 0) return;
    getUserInformation()
      .then((res) => {
        console.log(res.data);
        setFirstname(res?.data?.firstname);
        setLastname(res?.data?.lastname);
        setEmailUser(res?.data?.eMail);
        setUserId(res?.data?.id);
        setRoleId(res?.data?.roleId);
        setMfa(res?.data?.mfa);
        setUtc(res?.data?.utc);
        setUtp(res?.data?.utp);
        setIsAdmin(res?.data?.isAdmin);
        setIsSuperAdmin(res?.data?.isSuperAdmin);
        setAccountType(res?.data?.accountType);
        if (location.pathname !== "dashboard") {
          setRoleId(parseInt(localStorage.getItem("role") || "0", 10));
        }
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <TokenContext.Provider
      value={{
        token,
        setToken,
        firstname,
        setFirstname,
        lastname,
        setLastname,
        emailUser,
        setEmailUser,
        roleId,
        setRoleId,
        userId,
        setUserId,
        mfa,
        setMfa,
        utc,
        setUtc,
        utp,
        setUtp,
        isAdmin,
        setIsAdmin,
        isSuperAdmin,
        setIsSuperAdmin,
        accountType,
        setAccountType,
      }}
    >
      {children}
    </TokenContext.Provider>
  );
};

export const useTokenContext = () => {
  const context = useContext(TokenContext);
  if (context === null) {
    throw new Error("Le contexte doit être utilisé dans un TokenProvider");
  }
  return context;
};
