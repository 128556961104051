import NavBarAdmin from "../../components/Admin/Layout/NavBarAdmin";
import { useEffect } from "react";
import CustomerAdminTable from "../../components/Admin/Table/CustomerAdminTable";
import SearchBar from "../../components/Admin/Services/SearchBar";
import { useServiceStore } from "../../stores/admin/service";
import { usePartnerStore } from "../../stores/admin/users";
import { useParams } from "react-router-dom";
import { getUsersCustomersByIdPartner } from "../../services/Api/partnerService";
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";

const AdminCustomer = () => {
  // Store
  const { update, searchTerm } = useServiceStore();
  const { usersCustomer, setUsersCustomer } = usePartnerStore();
  const { idPartner } = useParams();

  // Function to display users partner filtered or not
  useEffect(() => {
    if (searchTerm !== "") {
      const filteredRequests = usersCustomer?.filter(
        (item: any) =>
          item?.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item?.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item?.eMail.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setUsersCustomer(filteredRequests);
    } else {
      getUsersCustomersByIdPartner(Number(idPartner))
        .then((data) => setUsersCustomer(data))
        .catch((error) => console.log(error));
    }
  }, [searchTerm, update]);

  return (
    <div className="w-screen h-screen bg-white flex flex-col justify-start items-start overflow-hidden ">
      <Header />
      <div className="w-full flex justify-start items-start">
        <NavBarAdmin />
        <div className="flex flex-col justify-start items-center w-11/12 h-screen ">
          <div className="w-11/12 flex justify-start sm:justify-between items-center 3xl:my-3">
            <h2 className="text-nxm-darkGray font-light 2xl:text-3xl text-xl font-K2D py-2 mt-5">
              Liste des clients :
            </h2>
          </div>
          <div className=" w-11/12 flex justify-between items-center">
            <div className="2xl:my-5  my-1 w-auto">
              <SearchBar />
            </div>
          </div>
          <div className="flex h-[90%] md:h-[75%] w-11/12 mb-20">
            <CustomerAdminTable />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AdminCustomer;
