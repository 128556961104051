import { useParams } from "react-router-dom";
import { updateUserPartner } from "../../../services/Api/adminService";
import { usePartnerStore } from "../../../stores/admin/users";
import { useEffect } from "react";
import { getRoleList } from "../../../services/Api/roleService";
import { getUserById } from "../../../services/Api/partnerService";
import { useServiceStore } from "../../../stores/admin/service";

const UpdateUserPartner = ({ id, closeModal }: any) => {
  const { idPartner } = useParams();
  const { idRole, setIdRole, roles, setRoles } = usePartnerStore();
  const { update, setUpdate } = useServiceStore();

  useEffect(() => {
    getUserById(Number(idPartner), Number(id))
      .then((res) => setIdRole(res.data.idRole))
      .catch((error) => console.log(error));

    getRoleList()
      .then((data) => {
        const formattedRole = data?.map((role: any) => ({
          value: role.id,
          label: role.role,
        }));
        setRoles(formattedRole);
        setIdRole(formattedRole.find((el: any) => el.id === idRole));
      })
      .catch((error) => console.log(error));
  }, [id]);

  const handleUpdateUser = () => {
    const data = {
      idRole,
    };
    updateUserPartner(Number(idPartner), Number(id), data)
      .then(() => {
        setUpdate(!update);
        closeModal();
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto md:w-2/4 my-6 mx-auto max-w-3xl min-w-[30%]">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-base md:text-3xl font-semibold text-center text-gray-700 font-K2D w-full">
                Modification d'un utilisateur
              </h3>
            </div>
            {/*body*/}
            <div className="text-sm md:text-xl flex w-1/2 m-auto flex-col justify-center items-centerp-5 text-gray-700 font-BarlowCondensed p-5">
              <p className="text-gray-500">Rôle :</p>
              <select
                className="border-[1px] rounded-md border-dark-lavender w-80 h-10 p-1 focus:outline-none"
                value={idRole}
                onChange={(e) => setIdRole(parseInt(e.target.value))}
                required
              >
                {roles?.map((role) => (
                  <option key={role.value} value={role.value}>
                    {role.label}
                  </option>
                ))}
              </select>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-4 border-t border-solid border-slate-200 rounded-b font-BarlowCondensed">
              <button
                className="text-red-300 background-transparent hover:text-red-500 font-bold uppercase px-6 py-2 2xl:text-lg text-base  outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => closeModal()}
              >
                Annuler
              </button>
              <button
                className="text-white bg-gradient-to-r from-light-lavender via-light-lavender to-dark-lavender hover:bg-gradient-to-br font-medium rounded-lg 2xl:text-lg text-base  px-5 py-2.5 text-center me-2 uppercase shadow hover:shadow-lg outline-none ease-linear transition-all duration-150"
                type="button"
                onClick={() => {
                  handleUpdateUser();
                }}
              >
                Modifier
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default UpdateUserPartner;
