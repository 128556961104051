import { useParams } from "react-router-dom";
import { usePartnerStore } from "../../../stores/admin/users";
import { useEffect } from "react";
import { getRoleList } from "../../../services/Api/roleService";
import {
  showToastError,
  showToastSuccess,
} from "../../../services/Toasts/toasts";
import { createUserPartner } from "../../../services/Api/adminService";
import { useServiceStore } from "../../../stores/admin/service";

const CreateUser = ({ closeModal }: any) => {
  const {
    selectedIdRole,
    setSelectedIdRole,
    email,
    setEmail,
    roles,
    setRoles,
  } = usePartnerStore();
  const { update, setUpdate } = useServiceStore();
  const { idPartner } = useParams();

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const user = {
      eMail: email,
      idRole: selectedIdRole,
    };

    if (selectedIdRole === 0) {
      showToastError("Veuillez sélectionner un rôle");
      return;
    } else {
      createUserPartner(Number(idPartner), user)
      .then((res) => {
        if (res.status === 208) {
          showToastError("Cet email existe déjà");
          return;
        } else {
          closeModal();
          showToastSuccess("Utilisateur créé avec succès");
          setUpdate(!update);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.message === '"eMail" must be a valid email') {
          showToastError("Veuillez saisir un email valide");
        } else {
          showToastError("Une erreur s'est produite");
        }
      });
    }
  };

  useEffect(() => {
    getRoleList()
      .then((data) => {
        const formattedRole = data?.map((role: any) => ({
          value: role.id,
          label: role.role,
        }));
        setRoles(formattedRole);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">
        <div className="relative w-auto md:w-1/4 my-6 mx-auto max-w-3xl min-w-[40%]">
          {/*content*/}
          <div className="border-0 relative flex flex-col w-full bg-white outline-none focus:outline-none rounded-t">
            {/*header*/}
            <div className="flex items-center justify-center p-4 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-xl 2xl:text-3xl font-semibold text-center font-K2D text-nxm-darkGray ">
                Créer un utilisateur
              </h3>
            </div>
            {/*body*/}
            <div className="w-10/12 m-auto flex flex-col items-center justify-center">
              <div className="flex justify-center items-center w-full py-4">
                <div className="w-1/2 mr-10 flex flex-col ">
                  <p className="text-gray-500">Email :</p>
                  <input
                    type="email"
                    className="border-[1px] border-slate-200 w-full h-auto p-2 bg-transparent rounded-md"
                    onChange={(e) => setEmail(e.target.value)}
                    minLength={2}
                    maxLength={200}
                    name="email"
                    id="email"
                    placeholder="Email"
                    required
                  />
                </div>
                <div className="w-1/2">
                  <p className="text-gray-500">Rôle :</p>
                  <select
                    className="w-full border-[1px] border-slate-200 h-10 bg-transparent rounded-md"
                    onChange={(e) =>
                      setSelectedIdRole(parseInt(e.target.value))
                    }
                    required
                  >
                    <option value="">Selectionner un rôle</option>
                    {roles.map((role) => (
                      <option key={role.value} value={role.value}>
                        {role.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          {/*footer*/}
          <div className="flex items-center justify-end 2xl:p-4 p-2 border-t border-solid border-slate-200 rounded-b bg-white font-BarlowCondensed">
            <button
              className="text-red-300 background-transparent hover:text-red-500 font-bold uppercase px-6 py-2 2xl:text-lg text-base  outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => {
                closeModal();
              }}
            >
              Annuler
            </button>
            <button
              className="text-white bg-gradient-to-r from-light-lavender via-light-lavender to-dark-lavender hover:bg-gradient-to-br font-medium rounded-lg 2xl:text-lg text-base  px-5 py-2.5 text-center me-2 uppercase  shadow hover:shadow-lg outline-none  ease-linear transition-all duration-150"
              type="button"
              onClick={() => {
                if (email === "") {
                  showToastError("Veuillez remplir tous les champs");
                } else {
                  handleSubmit(event);
                }
              }}
            >
              Créer
            </button>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default CreateUser;
