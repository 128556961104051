import { deleteFile } from "../../../../services/Api/documentService";
import { usePartnerRequestsStore2 } from "../../../../stores/Cac/Partner/partnerRequests";
import { showToastSuccess } from "../../../../services/Toasts/toasts";

const ModalDeleteDoc = ({ idRequest, idDoc, closeModal }: any) => {
  // Store
  const { updateRequest, setUpdateRequest } = usePartnerRequestsStore2();

  const handleDeleteFile = async (idDoc: number) => {
    await deleteFile(idRequest, idDoc);
    setUpdateRequest(!updateRequest);
    showToastSuccess("Le document a bien été supprimé");
    closeModal();
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto md:w-1/4 my-6 mx-auto max-w-3xl min-w-[35%]">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className=" w-full text-base md:text-2xl font-semibold text-center text-gray-700 font-K2D">
                Suppression de document
              </h3>
            </div>
            {/*body*/}
            <div className="text-sm md:text-xl text-center p-5 text-gray-700 font-BarlowCondensed">
              Etes-vous sûr de bien vouloir supprimer ce document ?
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-4 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm lg:text-xl outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => closeModal()}
              >
                Annuler
              </button>
              <button
                className="text-white bg-gradient-to-r from-light-lavender via-light-lavender to-dark-lavender hover:bg-gradient-to-br font-medium rounded-lg 2xl:text-lg text-base  px-5 py-2.5 text-center me-2 uppercase  shadow hover:shadow-lg outline-none  ease-linear transition-all duration-150"
                type="button"
                onClick={() => {
                  handleDeleteFile(idDoc);
                }}
              >
                Supprimer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default ModalDeleteDoc;
