import { useEffect, useState } from "react";
import { useTokenContext } from "../../../context/tokenContext";
import { useNavigate, useParams } from "react-router-dom";
import {
  getMissions,
  getWorkingFolders,
} from "../../../services/Api/customerService";
import PartnerMissionTable from "../../../components/Cac/Partner/Mission/PartnerMissionTable";
import StatusRequest from "../../../components/Cac/Customer/Request/StatusRequest";
import Return from "../../../assets/icons/return.svg";
import folder from "../../../assets/icons/folderBlue.svg";
import { usePartnerMissionStore } from "../../../stores/Cac/Partner/partnerMission";
import PartnerModalCreateUpdateMission from "../../../components/Cac/Partner/Mission/PartnerModalCreateUpdateMission";
import PartnerModalArchiveMission from "../../../components/Cac/Partner/Mission/PartnerModalArchiveMission";
import PopupLimitMission from "../../../components/Cac/Partner/Mission/PopupLimitMission";
import { renameFileWithoutUnderscore } from "../../../services/Utils/functionService";
import { ToastContainer } from "react-toastify";
import SideBar from "../../../components/Layout/SideBar";
import { usePartnerWorkingFolderStore } from "../../../stores/Cac/Partner/partnerWorkingFolder";
import CreateWorkingFolder from "../../../components/Cac/Partner/WorkingFolder/CreateWorkingFolder";
import Header from "../../../components/Layout/Header";
import Footer from "../../../components/Layout/Footer";
import { set } from "date-fns";

const PartnerMissions = () => {
  // Context
  const { roleId } = useTokenContext();

  // Hooks
  const navigate = useNavigate();
  const { idPartner, idCustomer } = useParams();

  // Store
  const {
    missions,
    setMissions,
    choiceModal,
    setChoiceModal,
    errorLimitMission,
    updateMission,
  } = usePartnerMissionStore();
  const { choiceModalWF, setChoiceModalWF, workingFolder, setWorkingFolder } =
    usePartnerWorkingFolderStore();

  // Function for get missions
  useEffect(() => {
    getMissions(Number(idCustomer))
      .then((data) => setMissions(data.missions))
      .catch(() => console.log("error"));
    getWorkingFolders(Number(idCustomer))
      .then((data) => setWorkingFolder(data[0]))
      .catch(() => console.log("error"));
  }, [idCustomer, choiceModal, updateMission]);

  return (
    <div className="w-screen h-screen bg-white flex flex-col justify-start items-start overflow-hidden">
      <ToastContainer position="top-center" />
      <Header />
      <div className="w-full flex justify-start items-start">
        <SideBar />
        <div className="flex flex-col justify-start items-center w-11/12 h-screen m-auto">
          <div className="w-11/12 flex justify-start sm:justify-between items-center 3xl:my-5 xl:my-2">
            <h2 className="my-2 text-grey-trio font-light 2xl:text-3xl text-xl mr-5 ml-1 font-K2D ">
              Missions :{" "}
              <span className="font-bold">
                {missions?.length > 0
                  ? renameFileWithoutUnderscore(missions[0].name)
                  : ""}
              </span>
            </h2>
            <div className="flex justify-center items-center">
              {!workingFolder && (
                <button
                  type="button"
                  className={
                    roleId === 2
                      ? "hidden"
                      : "hidden xl:w-54 xl:h-10 xl:flex mt-5 xl:mb-2 mr-2 items-center justify-center rounded-md border-[1px] border-yellow-trio p-2 bg-yellow-trio font-K2D text-md shadow-xl"
                  }
                  onClick={() => setChoiceModalWF("create")}
                >
                  + Créer un dossier travail
                </button>
              )}
              <button
                type="button"
                className={
                  roleId === 2
                    ? "hidden"
                    : "hidden xl:w-44 xl:h-10 xl:flex mt-5 xl:mb-2 items-center justify-center rounded-md border-[1px] border-yellow-trio p-2 bg-yellow-trio font-K2D text-md shadow-xl"
                }
                onClick={() => setChoiceModal("create")}
              >
                {" "}
                + Ajouter une mission{" "}
              </button>
            </div>
            {choiceModal === "create" || choiceModal === "update" ? (
              <PartnerModalCreateUpdateMission />
            ) : choiceModalWF === "create" ? (
              <CreateWorkingFolder />
            ) : null}
            {errorLimitMission ? <PopupLimitMission /> : null}
            {choiceModal === "archive" ? <PartnerModalArchiveMission /> : null}
          </div>
          <div className="hidden md:flex justify-between items-center w-11/12 pt-2 mb-5">
            <div className="flex justify-start items-center w-auto">
              <button
                type="button"
                className="flex"
                onClick={() => navigate(-1)}
                title="Retour"
              >
                <img src={Return} alt="return" />
              </button>
              {workingFolder !== undefined ? (
                <button
                  className="bg-light-lavender bg-opacity-20 rounded-md w-auto p-2 shadow-lg ml-5 flex justify-start items-center"
                  onClick={() =>
                    navigate(
                      `/partner/${idPartner}/customers/${idCustomer}/workingfolder/${workingFolder?.id}`
                    )
                  }
                >
                  <img src={folder} alt="dossier" className="w-4 h-4 mr-2" />
                  <p className="text-grey-trio text-sm lg:text-md">
                    Dossier de travail :{" "}
                    <span className="font-bold">
                      {workingFolder?.CategoryMission?.name} -{" "}
                      {workingFolder?.exercice}
                    </span>
                  </p>
                </button>
              ) : null}
            </div>
            <div className="flex sm:flex-row flex-col items-center justify-center">
              <StatusRequest />
            </div>
          </div>
          <div
            className={`w-11/12 h-[70%] rounded-md bg-white mb-20 overflow-y-scroll`}
          >
            <PartnerMissionTable />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PartnerMissions;
