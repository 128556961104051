import { dateFormat } from "../../../../services/Utils/functionService";
import { useCustomerRequestsStore } from "../../../../stores/Cac/Customer/customerRequests";

const RequestDetails = () => {
  //Store
  const { request } = useCustomerRequestsStore();
  return (
    <div className="w-10/12 m-auto rounded-md flex flex-col text-white font-BarlowCondensed text-lg ">
      <div className="grid grid-cols-3 justify-start items-start">
        <p className="font-extralight col-start-1 col-end-2 text-md text-white pr-2 py-4">
          Id :
        </p>
        <p className="text-md col-start-2 col-end-4 py-4">{request?.fakeId}</p>
      </div>
      <div className="grid grid-cols-3 justify-start items-start">
        <p className="font-extralight col-start-1 col-end-2 text-md text-white pr-2 py-4">
          Catégorie :
        </p>
        <p className="text-md col-start-2 col-end-4 py-4">
          {request?.CategoryDocRequest?.name}
        </p>
      </div>
      <div className="grid grid-cols-3 justify-start items-start">
        <p className="font-extralight col-start-1 col-end-2 text-md text-white pr-2 py-4">
          Demandeur :
        </p>
        <p className="text-md col-start-2 col-end-4 py-4">
          {request?.User?.fullName}
        </p>
      </div>
      <div className="grid grid-cols-3 justify-start items-start">
        <p className="font-extralight col-start-1 col-end-2 text-md text-white pr-2 py-4">
          Date création :
        </p>
        <p className="text-md col-start-2 col-end-4 py-4">
          {dateFormat(request?.createdAt)}
        </p>
      </div>
      <div className="grid grid-cols-3 justify-start items-start">
        <p className="font-extralight text-md text-white col-start-1 col-end-2 pr-2 py-4">
          Date limite :
        </p>
        <p className="text-md col-start-2 col-end-4 py-4">
          {dateFormat(request?.dueDate)}
        </p>
      </div>
      <div className="grid grid-cols-3 justify-start items-start">
        <p className="font-extralight col-start-1 col-end-2 text-md text-white pr-2 py-4">
          Etat :
        </p>
        <p className="col-start-2 col-end-4 py-4 text-md">
          {request?.State?.state}
        </p>
      </div>
      <div className="grid grid-cols-3 justify-start items-start">
        <p className="font-extralight col-start-1 col-end-2 text-md text-white py-4 ">
          Description :
        </p>
        <p className="col-start-2 col-end-4 text-md pt-4 whitespace-pre-line h-44 overscroll-auto">
          {request?.description}
        </p>
      </div>
      <div></div>
    </div>
  );
};

export default RequestDetails;
