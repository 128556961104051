import { useEffect, useRef } from "react";
import { usePartnerRequestDetailsStore } from "../../../../stores/Cac/Partner/partnerRequestDetails";
import {
  getCommentaries,
  postCommentary,
} from "../../../../services/Api/requestService";
import send from "../../../../assets/icons/sendWhite.svg";
import { useCustomerRequestsStore } from "../../../../stores/Cac/Customer/customerRequests";
import {
  dateFormat,
  hourFormat,
} from "../../../../services/Utils/functionService";

const RequestChat = () => {
  const { requestIdCustomer, updateRequestByClient, setUpdateRequestByClient } =
    useCustomerRequestsStore();

  const { messages, setMessages, message, setMessage } =
    usePartnerRequestDetailsStore();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (requestIdCustomer === 0) return;
    getCommentaries(Number(requestIdCustomer))
      .then((response) => setMessages(response))
      .catch((err) => console.log(err));
  }, [updateRequestByClient]);

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      if (textareaRef.current.scrollHeight > 120) {
        textareaRef.current.style.height = "120px";
        textareaRef.current.style.overflowY = "auto";
      } else {
        textareaRef.current.style.overflowY = "hidden";
      }
    }
  };

  const handlePostComment = () => {
    postCommentary(Number(requestIdCustomer), message)
      .then(() => {
        setMessage("");
        setUpdateRequestByClient(!updateRequestByClient);
      })
      .catch((err) => console.log(err));
  };

  const scrollToBottomAutomatic = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottomAutomatic();
  }, [messages]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handlePostComment();
    } else if (e.key === "Enter" && e.shiftKey) {
      setMessage(message + "\n");
    }
  };

  return (
    <div className="rounded-md flex flex-col font-BarlowCondensed text-lg h-2/3 bg-dark-lavender2 mt-5">
      <div className="bg-white rounded-md w-10/12 m-auto h-full relative flex flex-col">
        {messages?.length > 0 ? (
          <div className="h-[80%] w-full overflow-y-auto " ref={scrollRef}>
            {messages?.map((comment: any, index: number) => (
              <div
                key={index}
                className={`w-full flex ${
                  comment?.profil === "Partner"
                    ? "justify-end"
                    : comment?.profil === "Customer"
                    ? "justify-start"
                    : comment?.profil === "Deleted" || comment?.profil === ""
                    ? "justify-center"
                    : "justify-start"
                }`}
              >
                <div
                  className={`flex flex-col p-4 w-10/12 h-auto rounded-md m-2 ${
                    comment?.isRefusal
                      ? "bg-nxm-red"
                      : comment?.profil === "Partner"
                      ? "bg-dark-lavender2"
                      : comment?.profil === "Customer"
                      ? "bg-light-lavender"
                      : comment?.profil === "Deleted" || comment?.profil === ""
                      ? "bg-gray-400"
                      : "bg-gray-200"
                  }`}
                >
                  <div className="flex justify-start items-center">
                    {!comment?.isRefusal && (
                      <>
                        <div className="font-bold text-lg mr-2 text-gray-100">
                          {comment?.user}
                        </div>
                        <div className="text-gray-100">
                          {dateFormat(comment?.createdAt) +
                            " " +
                            hourFormat(comment?.createdAt)}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="text-gray-700 whitespace-pre-wrap leading-tight">
                    {comment?.text}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="h-[80%] flex items-center justify-center">
            <p className="italic text-grey-trio text-center">
              Aucun commentaire pour le moment.
            </p>
          </div>
        )}

        <div className="flex justify-center items-center absolute bottom-4 w-full">
          <textarea
            ref={textareaRef}
            className="w-10/12 p-2 rounded-lg bg-light-lavender text-grey-trio focus:outline-none"
            placeholder="Entrez votre commentaire"
            value={message}
            onKeyDown={(e) => handleKeyDown(e)}
            onChange={(e) => {
              setMessage(e.target.value);
              adjustTextareaHeight();
            }}
            style={{ resize: "none" }}
          />
          <button
            className="bg-dark-lavender text-white rounded-lg flex justify-center items-center ml-2 h-8 w-8"
            onClick={handlePostComment}
          >
            <img src={send} alt="envoi" className="h-4 w-4" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default RequestChat;
