import { dateFormat } from "../../../../services/Utils/functionService";
import PaperClip from "../../../../assets/icons/paperclip.svg";
import Down from "../../../../assets/icons/chevron-down.svg";
import { useCustomerRequestsStore } from "../../../../stores/Cac/Customer/customerRequests";
import useTooltip from "../../../../hooks/useTooltip";
import useSorting from "../../../../hooks/useSorting";
import message from "../../../../assets/icons/text-message.png";

const CustomerRequestTable = () => {
  // Store
  const {
    setRequestIdCustomer,
    setOpenDetailsCustomer,
    requests,
    setChoiceTab,
    displayAllRequests,
  } = useCustomerRequestsStore();

  // Hook
  const [tooltipState, handleMouseEnter, handleMouseLeave] = useTooltip();
  const [sortedData, handleSort] = useSorting(requests);

  return (
    <div className="w-full h-full overflow-y-scroll">
      <div className="w-full m-auto py-3 border-zinc-100 border-b-[1px] grid rounded-tl-md lg:grid-cols-9 grid-cols-3 xl:text-xl sticky top-0 z-10 font-BarlowCondensed text-grey-trio bg-white ">
        <div className="col-start-1 col-end-2 ml-2 font-bold">
          Id
          <button onClick={() => handleSort("fakeId")}>
            <img src={Down} alt="down" className="p-1" />
          </button>
        </div>
        <div className="col-start-2 col-end-3 flex relative items-center justify-start mr-4 md:mr-0 font-bold">
          Catégorie
          <button onClick={() => handleSort("CategoryDocRequest")}>
            <img src={Down} alt="down" className="p-1" />
          </button>
        </div>
        <div className="hidden lg:col-start-3 col-end-4 lg:flex font-bold">
          Création
          <button onClick={() => handleSort("createdAt")}>
            <img src={Down} alt="down" className="p-1" />
          </button>
        </div>
        <div className="hidden lg:flex lg:col-start-4 col-end-5 font-bold">
          Limite
          <button onClick={() => handleSort("dueDate")}>
            <img src={Down} alt="down" className="p-1" />
          </button>
        </div>
        <div className="hidden lg:inline-block lg:col-start-5 col-end-8 font-bold">
          Description
        </div>
        <div className="hidden lg:flex lg:col-start-8 col-end-9 font-bold">
          Demandeur
          <button onClick={() => handleSort("User")}>
            <img src={Down} alt="down" className="p-1" />
          </button>
        </div>
        <div className="lg:col-start-9 lg:col-end-10 col-start-3 col-end-4 font-bold">
          <div className="grid grid-cols-2">
            <div className="col-start-1 col-end-2 ">Docs</div>
            <div className="col-start-2 col-end-3  flex">
              Statut{" "}
              <button onClick={() => handleSort("State.state")}>
                <img src={Down} alt="down" className="p-1" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-[92%] font-BarlowCondensed">
        {sortedData
          ?.filter(
            (request) =>
              displayAllRequests ||
              request.isPeriodClosed === null ||
              request.isPeriodClosed === false
          )
          .map((request: any) => (
            <div
              className={`w-full m-auto grid grid-cols-3 hover:bg-light-lavender hover:bg-opacity-20 transition-all duration-150 lg:grid-cols-9 py-3 text-lg font-light text-start ${
                request?.isAssigned === true
                  ? "bg-white"
                  : "bg-gray-100 text-gray-400"
              }`}
              key={request?.id}
              onClick={() => {
                setRequestIdCustomer(request?.id);
              }}
            >
              <button
                type="button"
                className={`grid grid-cols-2 lg:grid-cols-8 lg:col-start-1 lg:col-end-9 w-full text-md 3xl:text-xl xl:text-base ${
                  request?.isAssigned === true
                    ? "cursor-pointer"
                    : "cursor-not-allowed"
                }`}
                onClick={() => {
                  if (request?.isAssigned !== true) {
                    setOpenDetailsCustomer(false);
                  } else {
                    setOpenDetailsCustomer(true);
                    if (request?.refused === true) {
                      setChoiceTab("Documents");
                    } else {
                      setChoiceTab("Documents");
                    }
                  }
                }}
              >
                <div className="w-full col-start-1 col-end-3 sm:col-start-1 sm:col-end-2 text-start grid grid-cols-5 gap-2">
                  <div className="col-start-1 col-end-2 ml-2">
                    {request?.fakeId}
                  </div>
                  <div
                    className="col-start-2 col-end-3"
                    onMouseEnter={(e) => {
                      if (request?.refused) {
                        handleMouseEnter("Requête refusée", e);
                      }
                    }}
                    onMouseLeave={
                      request?.refused ? handleMouseLeave : undefined
                    }
                  >
                    {request?.refused === true ? "📌" : " "}
                  </div>
                  <div
                    className="col-start-3 col-end-4 mr-2 flex justify-center items-center"
                    onMouseEnter={(e) => {
                      if (Object.keys(request?.reminder || {}).length > 0) {
                        handleMouseEnter(
                          `Requête relancé le ${dateFormat(
                            request.reminder?.date
                          )} par ${request?.reminder?.user}`,
                          e
                        );
                      }
                    }}
                    onMouseLeave={
                      request?.reminder ? handleMouseLeave : undefined
                    }
                  >
                    {Object.keys(request?.reminder || {}).length > 0
                      ? "📩"
                      : ""}
                  </div>
                  <div className="col-start-4 col-end-5 mr-2 flex justify-center items-center">
                    {request?.chatRead === false ? (
                      <div className="rounded-full w-2 h-2 bg-red-600"></div>
                    ) : (
                      ""
                    )}
                  </div>
                  {request?.commentaryCount !== 0 ? (
                    <div className="flex">
                      <img
                        src={message}
                        alt="message"
                        className="h-5 w-5 absolute mt-1"
                      />
                      <div className="rounded-full h-4 w-4 bg-white border-gray-300 border-[1px] left-4 relative -top-2 flex justify-center items-center">
                        <p className=" text-xs">{request?.commentaryCount}</p>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="col-start-2 col-end-3 md:mx-0 text-start">
                  {request?.CategoryDocRequest?.name}
                </div>
                <div className="hidden lg:inline-block lg:col-start-3 lg:col-end-4 text-start">
                  {dateFormat(request?.createdAt)}
                </div>
                <div className="hidden lg:inline-block lg:col-start-4 lg:col-end-5 text-start ">
                  {dateFormat(request?.dueDate)}
                </div>
                <div className="hidden lg:inline-block lg:col-start-5 lg:col-end-8 text-start whitespace-pre-line">
                  {request?.description}
                </div>
                <div className="hidden lg:inline-block lg:col-start-8 lg:col-end-9 text-start ">
                  {request?.User}
                </div>
              </button>
              <div className="lg:col-start-9 lg:col-end-10 text-center col-start-3 col-end-4">
                <div className="grid grid-cols-2">
                  <div className="flex justify-start items-center col-start-1 col-end-2 text-center">
                    <button className="flex">
                      <img
                        src={PaperClip}
                        alt="paperclip"
                        className="pr-2 mt-1"
                      />
                      {request?.documentCount}
                    </button>
                  </div>
                  <div className="flex justify-start items-center col-start-2 col-end-3 text-center ">
                    <div
                      className={`w-3 lg:w-6 h-3 border-[1px] rounded-md mr-2
                      ${
                        request?.State.id === 1
                          ? `border-filter-yellow bg-filter-yellow`
                          : null
                      }
                      ${
                        request?.State.id === 2
                          ? `border-filter-blue bg-filter-blue`
                          : null
                      }
                      ${
                        request?.State.id === 3
                          ? `border-filter-green bg-filter-green`
                          : null
                      }
                      ${
                        request?.State.id === 4
                          ? `border-filter-orange bg-filter-orange`
                          : null
                      }
                      `}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        {tooltipState.content && (
          <div
            className="bg-gray-600 text-white p-2 rounded-md text-sm absolute z-50 w-auto max-w-[200px] h-auto"
            style={{
              top: tooltipState.position.y,
              left: tooltipState.position.x,
            }}
          >
            {tooltipState.content}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerRequestTable;
