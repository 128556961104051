import { useState } from "react";
import { Carousel } from "primereact/carousel";
import { useDashboardStore } from "../../stores/Layout/dashboard";
import "../../css/carousel.css";
import { useNavigate } from "react-router-dom";
import { useTokenContext } from "../../context/tokenContext";

const CarouselDashboard = () => {
  const navigate = useNavigate();
  const { cardsPartner, cardsCustomer } = useDashboardStore();
  const { setRoleId } = useTokenContext();

  const [activeIndex, setActiveIndex] = useState(0);

  const allCards = [
    ...cardsPartner.map((card) => ({ ...card, type: "partner" })),
    ...cardsCustomer.map((card) => ({ ...card, type: "customer" })),
  ];

  const renderCard = (card: any) => {
    const id = card.type === "partner" ? card.idPartner : card.idCustomer;
    const name = card.type === "partner" ? card.namePartner : card.nameCustomer;
    const navigateTo =
      (card.type === "partner" && card.idRole === 1) ||
      (card.type === "partner" && card.idRole === 2) ||
      (card.type === "partner" && card.idRole === 3)
        ? `/partner/${id}/customers`
        : card.type === "customer"
        ? `/customer/${id}/missions`
        : `/error`;

    return (
      <div className="py-5">
        <button
          onClick={() => {
            localStorage.setItem("role", card?.idRole);
            setRoleId(card?.idRole);
            navigate(navigateTo);
          }}
          key={card.id}
          className="w-72 2xl:w-96 3xl:h-56 2xl:h-44 h-24 rounded-xl bg-white ag-courses_item custom-carousel-item flex justify-center items-center shadow-2xl"
        >
          <div className="w-full flex flex-col justify-center items-center h-full ag-courses-item_link">
            <div
              className={
                card.type === "partner"
                  ? "ag-courses-item_bg_partner"
                  : "ag-courses-item_bg"
              }
            ></div>
            {card.type === "customer" && (
              <div className="flex flex-col justify-center items-center">
                {card.logo ? (
                  <img
                    src={`data:image/png;base64,${card.logo}`}
                    alt="logo"
                    className="w-20 h-20 py-2 object-contain"
                  />
                ) : null}
                <p className="text-nxm-darkGray 2xl:text-2xl md:text-md font-bold text-sm mt-2 md:mt-0 font-K2D ag-courses-item_title underline">
                  {card?.namePartner}
                </p>
                <p className="text-nxm-darkGray 2xl:text-lg md:text-sm font-thin text-sm mt-2 md:mt-0 font-K2D ag-courses-item_title">
                  {name}
                </p>
              </div>
            )}
            {card.type === "partner" && (
              <div className="flex flex-col justify-center items-center">
                {card.logo ? (
                  <img
                    src={`data:image/png;base64,${card.logo}`}
                    alt="logo"
                    className="w-auto h-20 py-2 object-contain"
                  />
                ) : null}
                <p className="text-nxm-darkGray 2xl:text-2xl md:text-md font-bold text-sm mt-2 md:mt-0 font-K2D ag-courses-item_title underline">
                  {name}
                </p>
              </div>
            )}
            <p className="text-nxm-darkGray font-thin italic ag-courses-item_date-box text-xs md:text-md">
              {card?.idRole === 3
                ? "Accès administrateur"
                : (card?.idRole === 2 && card.type === "partner") ||
                  (card?.idRole === 1 && card.type === "partner")
                ? "Accès manager"
                : card?.idRole === 2 && card.type === "customer"
                ? "Accès utilisateur"
                : null}
            </p>
          </div>
        </button>
      </div>
    );
  };

  const onPageChange = (event: any) => {
    setActiveIndex(event.page);
  };

  const numPages = Math.ceil(allCards.length / 3);

  if (allCards?.length <= 3) {
    return (
      <div className="flex justify-center items-center w-11/12 xl:mr-10 mr-4 py-2">
        {allCards.map((card, index) => renderCard(card))}
      </div>
    );
  }

  return (
    <Carousel
      className="w-full custom-carousel"
      value={allCards}
      itemTemplate={renderCard}
      numVisible={3}
      numScroll={1}
      onPageChange={onPageChange}
      page={activeIndex}
      showIndicators={false}
    />
  );
};

export default CarouselDashboard;
