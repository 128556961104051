import React from "react";
import AppRoutes from "./Routes/AppRoutes";
import CookieConsentPopup from "./components/Layout/CookieConsentPopup";
import Cookies from "js-cookie";
import "./css/App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App: React.FC = () => {
  const consentCookie = Cookies.get("consentementCookies");
  return (
    <div className="w-screen">
      {!consentCookie ? <CookieConsentPopup /> : null}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ zIndex: 9999 }}
      />
      <AppRoutes />
    </div>
  );
};

export default App;
