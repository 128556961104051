import { useEffect } from "react";
import NavBarAdmin from "../../components/Admin/Layout/NavBarAdmin";
import { ToastContainer } from "react-toastify";
import InvoicesAdminTable from "../../components/Admin/Table/InvoicesAdminTable";
import { getInvoices } from "../../services/Api/partnerService";
import { useParams } from "react-router-dom";
import { useInvoiceStore } from "../../stores/admin/invoices";
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";

const AdminInvoices = () => {
  const { idPartner } = useParams();
  const { setInvoices } = useInvoiceStore();

  useEffect(() => {
    getInvoices(Number(idPartner))
      .then((data) => setInvoices(data))
      .catch((error) => console.log(error));
  }, [idPartner]);

  return (
    <div className="w-screen h-screen bg-white flex flex-col justify-start items-start overflow-hidden ">
      <Header />
      <div className="w-full flex justify-start items-start">
        <NavBarAdmin />
        <ToastContainer position="top-center" />
        <div className="flex flex-col justify-start items-center w-11/12 h-screen">
          <div className="w-11/12 flex justify-start sm:justify-between items-center xl:mt-3 2xl:mb-8">
            <h2 className="text-nxm-darkGray font-light 2xl:text-3xl text-xl font-K2D 2xl:py-2 ">
              Vos factures :{" "}
            </h2>
          </div>
          <div className="flex h-[90%] md:h-[75%] w-11/12 mb-20">
            <InvoicesAdminTable />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AdminInvoices;
