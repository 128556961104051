import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  convertData,
  cutName,
  dateFormat,
  splitMissionName,
} from "../../../services/Utils/functionService";
import Camembert from "../../../components/Cac/Partner/Utils/MonitoringElements/Camembert";
import Return from "../../../assets/icons/return.svg";
import ModalDocRequestDetails from "../../../components/Cac/Partner/Request/ModalDocRequestDetails";
import StatusRequest from "../../../components/Cac/Customer/Request/StatusRequest";
import { useCustomerRequestsStore } from "../../../stores/Cac/Customer/customerRequests";
import { useRecoveryFileStore } from "../../../stores/Utils/recoveryFIle";
import RequestDocuments from "../../../components/Cac/Customer/Request/RequestDocuments";
import { usePartnerRequestsStore2 } from "../../../stores/Cac/Partner/partnerRequests";
import eye from "../../../assets/icons/eye.svg";
import useModal from "../../../hooks/useModal";
import RelaunchPopup from "../../../components/Cac/Partner/Utils/RelaunchPopup";
import useTooltip from "../../../hooks/useTooltip";
import {
  getDocRequests,
  getMissionDataByCustomer,
  getPercentageMission,
  relaunchData,
} from "../../../services/Api/customerService";
import { useTokenContext } from "../../../context/tokenContext";
import { ToastContainer } from "react-toastify";
import SideBar from "../../../components/Layout/SideBar";
import Header from "../../../components/Layout/Header";
import Footer from "../../../components/Layout/Footer";

const RecoveryFile = () => {
  const navigate = useNavigate();
  const { idMission, idCustomer } = useParams();
  const { modalContent, isModalOpen, openModal, closeModal } = useModal();
  const { utp, utc } = useTokenContext();
  const { openDetailsCustomer, setOpenDetailsCustomer } =
    useCustomerRequestsStore();
  const [updateDetails, setUpdateDetails] = useState<boolean>(false);
  const [tooltipState, handleMouseEnter, handleMouseLeave] = useTooltip();
  const {
    percent,
    setPercent,
    customerName,
    setCustomerName,
    missionName,
    setMissionName,
    missionInProgress,
    setMissionInProgress,
    missionDeadLine,
    setMissionDeadLine,
    selectedIdsRequestDeadLine,
    setSelectedIdsRequestDeadLine,
    selectedIdsRequestInProgress,
    setSelectedIdsRequestInProgress,
    selectedAllDeadLine,
    setSelectedAllDeadLine,
    selectedAllInProgress,
    setSelectedAllInProgress,
  } = useRecoveryFileStore();
  const { requestId, setRequestId, openDetails, setOpenDetails } =
    usePartnerRequestsStore2();
  const { requestIdCustomer, setRequestIdCustomer } =
    useCustomerRequestsStore();

  useEffect(() => {
    getPercentageMission(Number(idCustomer), Number(idMission))
      .then((data: any) => {
        const convertedData = convertData(data.percentage);
        setPercent(convertedData);
        getMissionDataByCustomer(Number(idCustomer), Number(idMission))
          .then((data) => {
            setMissionName(data.name);
            const customerNameCut = cutName(data.name);
            setCustomerName(customerNameCut);
            if (idCustomer && idMission)
              relaunchData(parseInt(idCustomer), parseInt(idMission))
                .then((res) => {
                  const filteredDataDeadline = res.data.filter(
                    (item: any) => item.State.id === 4
                  );
                  setMissionDeadLine(filteredDataDeadline);
                  const filteredDataInProgress = res.data.filter(
                    (item: any) => item.State.id === 1
                  );
                  setMissionInProgress(filteredDataInProgress);
                })
                .catch((error) => console.log(error));
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));
  }, [
    updateDetails,
    openDetails,
    openDetailsCustomer,
    requestId,
    requestIdCustomer,
  ]);

  const handlSelectedAllDeadLine = () => {
    setSelectedAllDeadLine((prev) => !prev);

    if (!selectedAllDeadLine) {
      setSelectedIdsRequestDeadLine(
        missionDeadLine
          .map((item) => item.id)
          .filter((item): item is number => typeof item === "number")
      );
    } else {
      setSelectedIdsRequestDeadLine([]);
    }
  };

  const handlSelectedAllInProgress = () => {
    setSelectedAllInProgress((prev) => !prev);

    if (!selectedAllInProgress) {
      setSelectedIdsRequestInProgress(
        missionInProgress
          .map((item) => item.id)
          .filter((item): item is number => typeof item === "number")
      );
    } else {
      setSelectedIdsRequestInProgress([]);
    }
  };

  const handleCheckboxChangeDeadLine = (
    e: React.ChangeEvent<HTMLInputElement>,
    idRequest: number
  ) => {
    if (e.target.checked) {
      setSelectedIdsRequestDeadLine((prev) => [...prev, idRequest]);
    } else {
      setSelectedIdsRequestDeadLine((prev) =>
        prev.filter((id) => id !== idRequest)
      );
    }
  };

  const handleCheckboxChangeInProgress = (
    e: React.ChangeEvent<HTMLInputElement>,
    idRequest: number
  ) => {
    if (e.target.checked) {
      setSelectedIdsRequestInProgress((prev) => [...prev, idRequest]);
    } else {
      setSelectedIdsRequestInProgress((prev) =>
        prev.filter((id) => id !== idRequest)
      );
    }
  };

  return (
    <div className="w-screen h-screen bg-white overflow-hidden flex flex-col">
      <Header />
      <div className="w-full h-full  flex justify-start items-start">
        {isModalOpen ? modalContent : null}
        <ModalDocRequestDetails setUpdateDetails={setUpdateDetails} />
        <RequestDocuments />
        <ToastContainer position="top-center" />
        <SideBar />
        <div className="flex flex-col justify-start items-center w-full 2xl:mt-5">
          <div className="flex justify-start items-start w-full h-1/2 font-BarlowCondensed 2xl:text-xl ">
            <div className="flex flex-col items-start justify-start w-5/12 2xl:ml-10 2xl:mt-10 mt-5 ml-5">
              <h2 className="text-nxm-darkGray font-light 2xl:text-4xl text-2xl pt-2 flex font-K2D">
                Client :{" "}
                <span className="flex font-bold pl-2">{customerName}</span>
              </h2>
              <p className="font-light pt-2 flex">
                Récapitulatif des états de requêtes pour la mission :
                <span className="flex font-bold pl-2">
                  {splitMissionName(missionName)}
                </span>
              </p>
              <p className="font-light pt-8">
                Afin de mettre à disposition de nos auditeurs les documents en
                attentes,<br></br> nous vous invitons à vous connecter sur votre
                espace client sur le site
              </p>
              <button
                type="button"
                className="flex mt-8"
                onClick={() => navigate(-1)}
              >
                <img src={Return} alt="return" />
              </button>
              <div className="">
                <Camembert data={percent} />
                <StatusRequest />
              </div>
            </div>

            <div className="flex flex-col justify-start items-start w-7/12 h-1/2 2xl:mt-10 mt-5">
              <div className="w-11/12 flex justify-between items-center h-20">
                <p className="font-light pb-2 mr-5">
                  Liste des requêtes hors délais :
                </p>

                {utp === 1 ? (
                  <button
                    className={`mb-2 border-[1px] rounded-md ${
                      missionDeadLine.length === 0
                        ? "bg-gray-400 "
                        : "bg-red-500"
                    }  text-white px-4 py-2`}
                    disabled={missionDeadLine.length === 0}
                    onClick={() =>
                      openModal(<RelaunchPopup closeModal={closeModal} />)
                    }
                  >
                    Relancer les requêtes en retard
                  </button>
                ) : null}
              </div>
              <div className="w-11/12 border-2 border-zinc-100 rounded-md bg-white ">
                <div className="bg-zinc-300 border-2 p-3 border-zinc-100 w-full flex justify-start items-center text-sm">
                  <div className="w-[5%] flex justify-start items-center ">
                    {utp === 1 ? (
                      <input
                        type="checkbox"
                        checked={selectedAllDeadLine}
                        onClick={() => handlSelectedAllDeadLine()}
                      />
                    ) : null}
                  </div>
                  <div className="w-[5%] flex justify-start items-center">
                    Id
                  </div>
                  <div className="w-[60%] flex justify-start items-center mr-4">
                    Contact client
                  </div>
                  <div className="w-[15%] flex justify-start items-center">
                    Catégorie
                  </div>
                  <div className="w-[10%] flex justify-start items-center">
                    Jours de retard
                  </div>
                  <div className="w-[5%] flex justify-start items-center"></div>
                </div>
                <div className="w-full 2xl:h-72 h-64 overflow-y-scroll text-sm">
                  {missionDeadLine?.map((mission: any) => (
                    <div
                      key={mission?.id}
                      className={`flex justify-start items-center p-3 border-b-2 border-zinc-100  ${
                        mission?.isAssigned === true
                          ? "bg-white"
                          : "bg-gray-100 text-gray-400"
                      }`}
                    >
                      {utp === 1 ? (
                        <div className="flex justify-start items-center w-[5%]">
                          <input
                            type="checkbox"
                            className="mr-2"
                            name=""
                            id="mx-2"
                            value={mission.id}
                            checked={selectedIdsRequestDeadLine.includes(
                              mission.id
                            )}
                            onChange={(e) =>
                              handleCheckboxChangeDeadLine(e, mission.id)
                            }
                          />
                          <div
                            className="pb-1 flex justify-start items-center"
                            onMouseEnter={(e) => {
                              console.log(e);
                              if (
                                Object.keys(mission?.reminder || {}).length > 0
                              ) {
                                handleMouseEnter(
                                  `Requête relancé le ${dateFormat(
                                    mission?.reminder?.date
                                  )} par ${mission?.reminder?.user}`,
                                  e
                                );
                              }
                            }}
                            onMouseLeave={
                              mission?.reminder ? handleMouseLeave : undefined
                            }
                          >
                            {Object.keys(mission?.reminder || {}).length > 0
                              ? "📩"
                              : ""}
                          </div>
                        </div>
                      ) : null}
                      <div className="w-[5%] flex justify-start items-center">
                        {mission?.fakeId}
                      </div>
                      <div className="w-[60%] flex justify-start items-start mr-4">
                        {mission?.Emails.map((email: string) => (
                          <p className="mr-2">{email};</p>
                        ))}
                      </div>
                      <div className="w-[15%] flex justify-start items-center">
                        {mission?.CategoryDocRequest.name}
                      </div>
                      <div className="w-[10%] flex justify-center items-center">
                        {mission?.overdue}
                      </div>
                      <div className="w-[5%] flex justify-end items-center">
                        <button
                          type="button"
                          className={`w-full flex justify-end items-center mr-4 h-4 ${
                            mission?.isAssigned === true
                              ? "cursor-pointer"
                              : "cursor-not-allowed"
                          }`}
                          onClick={() => {
                            if (utc === 1 && mission?.isAssigned === true) {
                              setOpenDetailsCustomer(true);
                              setRequestIdCustomer(mission?.id);
                            } else if (
                              utc === 1 &&
                              mission?.isAssigned === false
                            ) {
                              setOpenDetailsCustomer(false);
                            } else setOpenDetails(true);
                            setRequestId(mission?.id);
                          }}
                        >
                          <img src={eye} alt="eye" />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="w-11/12 flex justify-between items-center h-20 2xl:mt-10 mt-5">
                <p className="font-light pb-2 mr-5">
                  Liste des requêtes en attente :
                </p>
                {utp === 1 ? (
                  <button
                    className={`mb-2 border-[1px] rounded-md ${
                      missionInProgress.length === 0
                        ? "bg-gray-400"
                        : "bg-orange-400"
                    }  text-white px-4 py-2 `}
                    disabled={missionInProgress.length === 0}
                    onClick={() =>
                      openModal(
                        <RelaunchPopup
                          closeModal={closeModal}
                          selectedIdsRequestInProgress={
                            selectedIdsRequestInProgress
                          }
                        />
                      )
                    }
                  >
                    Informer des requêtes en attente
                  </button>
                ) : null}
              </div>

              <div className=" w-11/12 border-2 border-zinc-100 rounded-md bg-white h-auto ">
                <div className="bg-zinc-300 border-2 p-3 border-zinc-100 w-full flex justify-start items-center text-sm">
                  <div className="w-[5%] flex justify-start items-center ">
                    {utp === 1 ? (
                      <input
                        type="checkbox"
                        checked={selectedAllInProgress}
                        onClick={() => handlSelectedAllInProgress()}
                      />
                    ) : null}
                  </div>
                  <div className="w-[5%] flex justify-start items-center">
                    Id
                  </div>
                  <div className="w-[60%] flex justify-start items-center mr-4">
                    Contact client
                  </div>
                  <div className="w-[15%] flex justify-start items-center">
                    Catégorie
                  </div>
                  <div className="w-[10%] flex justify-start items-center">
                    Jours de retard
                  </div>
                  <div className="w-[5%] flex justify-start items-center"></div>
                </div>
                <div className="w-full 2xl:h-72 h-64 overflow-y-scroll text-sm">
                  {missionInProgress?.map((mission: any) => (
                    <div
                      key={mission?.id}
                      className={`flex justify-start items-center p-3 border-b-2 border-zinc-100  ${
                        mission?.isAssigned === true
                          ? "bg-white"
                          : "bg-gray-100 text-gray-400"
                      }`}
                    >
                      {utp === 1 ? (
                        <div className="flex justify-start items-center w-[5%]">
                          <input
                            type="checkbox"
                            name=""
                            className="mr-2"
                            id="mx-2"
                            value={mission.id}
                            checked={selectedIdsRequestInProgress.includes(
                              mission.id
                            )}
                            onChange={(e) =>
                              handleCheckboxChangeInProgress(e, mission.id)
                            }
                          />
                          <div
                            className="pb-1 flex justify-start items-center"
                            onMouseEnter={(e) => {
                              console.log(e);
                              if (
                                Object.keys(mission?.reminder || {}).length > 0
                              ) {
                                handleMouseEnter(
                                  `Informer le ${dateFormat(
                                    mission?.reminder?.date
                                  )} par ${mission?.reminder?.user}`,
                                  e
                                );
                              }
                            }}
                            onMouseLeave={
                              mission?.reminder ? handleMouseLeave : undefined
                            }
                          >
                            {Object.keys(mission?.reminder || {}).length > 0
                              ? "📩"
                              : ""}
                          </div>
                        </div>
                      ) : null}
                      <div className="w-[5%] flex justify-start items-center">
                        {mission?.fakeId}
                      </div>
                      <div className="w-[60%] flex justify-start items-start mr-4">
                        {mission?.Emails.map((email: string) => (
                          <p className="mr-2">{email};</p>
                        ))}
                      </div>
                      <div className="w-[15%] flex justify-start items-center">
                        {mission?.CategoryDocRequest.name}
                      </div>
                      <div className="w-[10%] flex justify-center items-center">
                        {mission?.overdue}
                      </div>
                      <div className="w-[5%] flex justify-end items-center">
                        <button
                          type="button"
                          className={`w-full flex justify-end items-center mr-4 h-4 ${
                            mission?.isAssigned === true
                              ? "cursor-pointer"
                              : "cursor-not-allowed"
                          }`}
                          onClick={() => {
                            if (utc === 1 && mission?.isAssigned === true) {
                              setOpenDetailsCustomer(true);
                              setRequestIdCustomer(mission?.id);
                            } else if (
                              utc === 1 &&
                              mission?.isAssigned === false
                            ) {
                              setOpenDetailsCustomer(false);
                            } else setOpenDetails(true);
                            setRequestId(mission?.id);
                          }}
                        >
                          <img src={eye} alt="eye" />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
                {tooltipState.content && (
                  <div
                    className="bg-gray-600 text-white p-2 rounded-md text-sm absolute z-50 w-auto max-w-[200px] h-auto"
                    style={{
                      top: tooltipState.position.y,
                      left: tooltipState.position.x,
                    }}
                  >
                    {tooltipState.content}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RecoveryFile;
