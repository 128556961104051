import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { resetPassword } from "../../services/Api/loginService";
import Eyes from "../../assets/icons/eye.svg";
import { useConnexionStore } from "../../stores/Layout/connexion";
import { showToastError, showToastSuccess } from "../../services/Toasts/toasts";
import { ToastContainer } from "react-toastify";
import logo from "../../assets/logos/logoX.png";

const ResetPassword = () => {
  // Hooks
  const navigate = useNavigate();
  const location = useLocation();

  // Store
  const {
    passwordResetPassword,
    setPasswordResetPassword,
    verifyPasswordResetPassword,
    setVerifyPasswordResetPassword,
    passwordInputType,
    setPasswordInputType,
  } = useConnexionStore();

  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  // function for password visibility
  const togglePasswordVisibility = () => {
    setPasswordInputType(
      passwordInputType === "password" ? "text" : "password"
    );
  };

  const handlePassword = (e: any) => {
    e.preventDefault();
    if (passwordResetPassword !== verifyPasswordResetPassword) {
      showToastError("Les mots de passe ne correspondent pas.");
      return;
    }
    if (passwordResetPassword.length < 8) {
      showToastError("Le mot de passe doit contenir au moins 8 caractères.");
      return;
    } else {
      resetPassword(token, verifyPasswordResetPassword)
        .then((response) => {
          if (response?.status === 200) {
            showToastSuccess("Le mot de passe a bien été réinitialisé !");
            setPasswordInputType("password");
            setTimeout(() => {
              navigate("/");
            }, 1.8);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div className="w-screen h-screen  flex flex-col justify-start items-center">
      <div className="hidden md:block absolute top-2 left-14">
        <img src={logo} alt="logo Nexum" className="w-10 h-14" />
      </div>
      <ToastContainer position="top-center" />
      <p className="font-K2D font-extrabold text-grey-trio text-2xl text-center mb-10 mt-5">
        NEXUM
      </p>
      <form
        action="#"
        className="bg-light-lavender rounded-md flex flex-col justify-center items-center w-4/12 m-auto font-BarlowCondensed text-lg 2xl:mt-10 mt-5 text-nxm-darkGray"
      >
        <p className="w-full text-xl text-center py-5">
          Veuillez renseigner votre nouveau mot de passe.
        </p>
        <p className="w-10/12 3xl:w-8/12 m-auto text-base pb-10 text-nxm-darkGray">
          Celui ci doit contenir au minimum 8 caractères, au maximum 30, une
          majuscule, une minuscule, un chiffre et un caractère spécial.
        </p>
        <div className="w-10/12 m-auto flex flex-col justify-center items-center">
          <label htmlFor="password" className="text-grey-trio text-xl mb-4">
            Entrez votre mot de passe :
          </label>
          <div className="relative w-full flex justify-center items-center">
            <button
              type="button"
              className="absolute top-2 right-2"
              onClick={togglePasswordVisibility}
            >
              <img src={Eyes} alt="oeil" />
            </button>
            <input
              type={passwordInputType}
              className="border-b-[1px] w-1/2 m-auto mb-5 h-10 rounded-md bg-white focus:outline-none mt-1 shadow-xl pl-1"
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              onChange={(e) => setPasswordResetPassword(e.target.value)}
              name="newPassword"
              placeholder="********"
              required
            />
          </div>
          <label
            htmlFor="password"
            className="text-grey-trio  text-xl mb-4 mt-5"
          >
            Verifiez votre mot de passe :
          </label>
          <div className="relative w-full  flex justify-center items-center">
            <input
              type={passwordInputType}
              className="border-b-[1px] w-1/2 mb-5 h-10 rounded-md bg-white focus:outline-none mt-1 shadow-xl pl-1"
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              name="password"
              onChange={(e) => setVerifyPasswordResetPassword(e.target.value)}
              placeholder="********"
              required
            />
            <button
              type="button"
              className="absolute top-2 right-2"
              onClick={togglePasswordVisibility}
            >
              <img src={Eyes} alt="oeil" />
            </button>
          </div>
          <div className="w-full flex justify-center items-center my-10">
            <button
              type="submit"
              onClick={(e) => handlePassword(e)}
              className="text-white bg-gradient-to-r from-light-lavender via-light-lavender to-dark-lavender hover:bg-gradient-to-br font-medium rounded-lg 2xl:text-lg text-base  px-5 py-2.5 text-center me-2 uppercase  shadow hover:shadow-lg outline-none  ease-linear transition-all duration-150"
            >
              Réinitialiser
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
