import Cookies from "js-cookie";
import { logout } from "../Api/loginService";

//********************* Redirection **************** //
const consentCookie = Cookies.get("consentementCookies");

export const redirection = (location: { pathname: string; }, navigate: (arg0: string) => void, isSuperAdmin: number) => {
  if (consentCookie !== null ) {
  if (
      location.pathname !== "/resetpassword" &&
      isSuperAdmin === 0
      ) {
        navigate("/dashboard");
    } else if (isSuperAdmin === 1) {
      navigate("/superadmin/dashboard");
    } else if (location.pathname.includes("/resetpassword")) {
      return;
    } else {
      navigate("/");
      logout()
    }
  }
}

// ******************** FORMAT ******************** //
export const splitMissionName = (name: string) => {
  if (!name) return "";
  return name.replaceAll("_", " ");
};

export const dateFormat = (date: string): string => {
  const newDate = new Date(date);

  const day = newDate.getUTCDate().toString().padStart(2, "0");
  const month = (newDate.getUTCMonth() + 1).toString().padStart(2, "0");
  const year = newDate.getUTCFullYear().toString();

  return `${day}/${month}/${year}`;
};

export const formatDateForSendRequest = (date: Date): string => {
  // Créer une nouvelle date en compensant le décalage horaire
  date.setHours(date.getHours() + 1);

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};


export const hourFormat = (date: string): string => {
  const newDate = new Date(date);

  const hour = newDate.getUTCHours().toString().padStart(2, "0");
  const minutes = newDate.getUTCMinutes().toString().padStart(2, "0");

  return `${hour}h${minutes}`;
};

// Function to format the file name when is too long
export const fileNameFormat = (fileName: string) => {
  const name = fileName.split(".");
  return name[0];
};

export const cutName = (name : string) => {
  const index = name.indexOf('_');
  if (index !== -1) return name.slice(0, index);
  return name;
}

export const formatData = (data: any) => {
  const formattedData = Object.keys(data[0]).map((key) => ({
    name: key,
    value: parseFloat(data[0][key]),
  }));

  return formattedData;
};

export function formatDate(inputDate: Date | string | null): string {
  if (inputDate === null) return '';

  let date: Date;
  if (typeof inputDate === "string") {
    date = new Date(inputDate);
  } else {
    date = inputDate;
  }

  const day = ("0" + date.getUTCDate()).slice(-2);
  const month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
  const year = date.getUTCFullYear();

  return `${year}-${month}-${day}`;
}

export const verifyEmail = (email: string) => {
  const regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/;
  return regexEmail.test(email);
}

export const normalizeFileName = (fileName: string) => {
  const parts = fileName.split('.');
  const extension = parts.pop();
  const namePart = parts.join('.'); 

  const cleanedName = namePart.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z0-9.]/g, "");

  return `${cleanedName}.${extension}`;
}

export const renameFileWithoutUnderscore = (text : string) => text.substring(0, text.indexOf('_'));

// ******************** PERCENTAGE ******************** //
export const getProgressData = (percentState: any, countsState: any) => {
  const nonZeroCounts = Object.values(countsState).filter(
    (value: any) => parseFloat(value) > 0
  );
  const numNonZeros = nonZeroCounts.length;

  return Object.keys(countsState).reduce((acc: any[], key, index, array) => {
    const count = countsState[key];
    const percent = parseFloat(percentState[key]);
    
    if (count > 0) {
      let color = "bg-gray-300";
      if (key === "En Attente") color = "bg-filter-yellow";
      if (key === "Délivrée") color = "bg-filter-blue";
      if (key === "Accepté") color = "bg-filter-green";
      if (key === "En retard") color = "bg-filter-orange";

      let classes = `h-full ${color}`;

      if (numNonZeros === 1) {
        classes += " rounded-l-full rounded-r-full";
      } else {
        if (acc.length === 0) {
          classes += " rounded-l-full rounded-r-none";
        } else if (acc.length === numNonZeros - 1) {
          classes += " rounded-r-full rounded-l-none";
        } else {
          classes += " rounded-none";
        }
      }
      acc.push({
        classes: classes,
        percent: percent,
        count: count,
      });
    }

    return acc;
  }, []);
};



export const convertData = (data: { [key: string]: string }) => {
  return Object.keys(data).map((key) => ({
    name: key,
    value: parseFloat(data[key]),
  }));
};


// ******************** Yaxis ******************** //
export const yAxisTickFormatter = (value: number) => {
  return Math.round(value).toString();
};

// ******************** Math round sup ******************** //
export const mathRoundSup = (nb: number) => {
  const nombreArrondi = Math.round(nb * 2) / 2;
  return nombreArrondi.toFixed(1).replace(/\.0$/, '');
}

// ******************** Change Favicon ******************* //
export const changeFavicon = (src: string) => {
  let link: HTMLLinkElement = document.querySelector("link[rel*='icon']") as HTMLLinkElement;
  if (!link) {
    link = document.createElement('link') as HTMLLinkElement;
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  link.type = 'image/png';
  link.href = src;
}

export const nameFileWithoutPath = (name: string) => {
  if(name.includes('/')) {
  const parts = name.split('/');
  return parts[parts.length - 1];
  } else if(name.includes('\\')) {
    const parts = name.split('\\');
    return parts[parts.length - 1];
  } else {
    return name;
  }
}