import logs from "../../assets/icons/logs.svg";
import { useSuperAdminPartnerStore } from "../../stores/superadmin/superadminpartner";
import { useNavigate } from "react-router-dom";
import users from "../../assets/icons/user-search.svg";
import setting from "../../assets/icons/wrench.svg";

const NavBarSuperAdmin = () => {
  const { pathVisible, setPathVisible, setModePartner } =
    useSuperAdminPartnerStore();
  const navigate = useNavigate();

  return (
    <div className="w-14 h-screen border-r-[1px] border-light-lavender shadow-xl flex flex-col justify-start items-center bg-light-lavender z-50">
      <div className="flex flex-col justify-center items-center">
        <button
          className={`h-12 w-full flex justify-center items-center`}
          onClick={() => {
            setPathVisible("partners");
            navigate("/superadmin/dashboard");
            setModePartner("create");
          }}
        >
          <img src={users} alt="" className="w-6 h-6" />
        </button>
        <button
          className={`h-12 w-full flex justify-center items-center `}
          onClick={() => {
            setPathVisible("logs");
            navigate("/superadmin/dashboard");
          }}
        >
          <img src={logs} alt="" className="w-6 h-6" />
        </button>
        <button
          className={`h-12 w-full flex justify-center items-center`}
          onClick={() => {
            setPathVisible("settings");
            navigate("/superadmin/dashboard");
          }}
        >
          <img src={setting} alt="" className="w-6 h-6" />
        </button>
      </div>
    </div>
  );
};

export default NavBarSuperAdmin;
