import NavBarAdmin from "../../components/Admin/Layout/NavBarAdmin";
import { useEffect } from "react";
import { useCategoriesServicesStore } from "../../stores/admin/categoriesServices";
import pen from "../../assets/icons/edit.svg";
import trash from "../../assets/icons/trash.svg";
import {
  getCategoriesMissions,
  renewableMission,
} from "../../services/Api/adminService";
import { useParams } from "react-router-dom";
import {
  createCategoryDocRequestByIdPartner,
  createCategoryMissionByIdPartner,
  deleteCategoryDocRequestByIdPartner,
  deleteCategoryMissionByIdPartner,
  getCategoriesDocRequestByIdPartner,
  updateCategoryDocRequestByIdPartner,
  updateCategoryMissionByIdPartner,
} from "../../services/Api/partnerService";
import { showToastError } from "../../services/Toasts/toasts";
import { ToastContainer } from "react-toastify";
import useTooltip from "../../hooks/useTooltip";
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";

const AdminCategories = () => {
  const {
    services,
    setServices,
    newService,
    setNewService,
    updateService,
    setUpdateService,
    idService,
    setIdService,
    categories,
    setCategories,
    newCategory,
    setNewCategory,
    editingServiceId,
    setEditingServiceId,
    modifyNameCategory,
    setModifyNameCategory,
    modifyNameService,
    setModifyNameService,
    setIdCategory,
    editingCategoryId,
    setEditingCategoryId,
  } = useCategoriesServicesStore();
  const { idPartner } = useParams();
  // Hook
  const [tooltipState, handleMouseEnter, handleMouseLeave] = useTooltip();

  useEffect(() => {
    getCategoriesMissions(Number(idPartner))
      .then((data) => setServices(data))
      .catch((err) => console.log(err));

    if (idService !== 0) {
      getCategoriesDocRequestByIdPartner(Number(idPartner), idService)
        .then((data) => setCategories(data))
        .catch((err) => console.log(err));
    }
  }, [updateService, idService]);

  // This is for services
  const handleCreateService = () => {
    const data = {
      name: newService,
    };

    createCategoryMissionByIdPartner(Number(idPartner), data)
      .then(() => {
        setUpdateService(!updateService);
        setNewService("");
      })
      .catch((err) => console.log(err));
  };

  const handleDeleteService = (idService: number) => {
    deleteCategoryMissionByIdPartner(Number(idPartner), Number(idService))
      .then(() => setUpdateService(!updateService))
      .catch((err) => {
        showToastError(
          "Vous ne pouvez pas supprimer un service qui est lié à des missions en cours."
        );
        console.log(err);
      });
  };

  const handleupdateServiceName = (idService: number) => {
    const data = {
      name: modifyNameService,
    };

    updateCategoryMissionByIdPartner(Number(idPartner), idService, data)
      .then(() => {
        setUpdateService(!updateService);
        setEditingServiceId(null);
        setModifyNameService("");
      })
      .catch((err) => console.log(err));
  };

  // This is for categories
  const handleCreateCategory = () => {
    const data = {
      name: newCategory,
      CategoryMissionId: idService,
    };
    if (idService === 0 || idService === undefined)
      return showToastError(
        "Veuillez selectionner un service avant de créer une catégorie."
      );
    createCategoryDocRequestByIdPartner(Number(idPartner), data)
      .then(() => {
        setUpdateService(!updateService);
        setNewCategory("");
      })
      .catch((err) => console.log(err));
  };

  const handleDeleteCategory = (idCategory: number) => {
    deleteCategoryDocRequestByIdPartner(Number(idPartner), idCategory)
      .then(() => {
        setUpdateService(!updateService);
      })
      .catch((err) => {
        showToastError(
          "Vous ne pouvez pas supprimer une catégorie qui est lié à des requêtes en cours."
        );
        console.log(err);
      });
  };

  const handleUpdateCategory = (idCategory: number) => {
    const data = {
      name: modifyNameCategory,
    };
    updateCategoryDocRequestByIdPartner(Number(idPartner), idCategory, data)
      .then(() => {
        setUpdateService(!updateService);
        setEditingCategoryId(null);
        setModifyNameCategory("");
      })
      .catch((err) => console.log(err));
  };

  const handleRenewable = (idCategory: number, checked: boolean) => {
    const data = {
      renewable: checked,
    };
    renewableMission(Number(idPartner), idCategory, data)
      .then(() => {
        setUpdateService(!updateService);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="w-screen h-screen bg-white flex flex-col justify-start items-start overflow-hidden ">
      <Header />
      <div className="w-full flex justify-start items-start">
        <NavBarAdmin />
        <ToastContainer position="top-center" />
        <div className="flex flex-col justify-start items-center w-11/12 h-screen ">
          <div className="h-10 w-11/12 flex justify-start items-center xl:my-3">
            <h2 className="text-grey-trio font-light text-2xl 2xl:text-4xl font-K2D ">
              Configurer vos services et categories :{" "}
            </h2>
          </div>
          <div className="flex flex-col w-full h-full">
            <div className="mt-5 h-[80%] w-full m-auto rounded-md flex ">
              <div className="w-1/2 flex flex-col h-auto text-grey-trio font-BarlowCondensed text-lg">
                <div className="flex justify-center items-center w-3/4 m-auto">
                  <div className="w-full flex flex-col justify-start items-start mt-5">
                    <p className="text-center mr-5 font-bold text-grey-trio">
                      Nouveau Service :
                    </p>
                    <input
                      type="text"
                      name="service"
                      id="service"
                      placeholder="Service"
                      value={newService}
                      onChange={(e) => setNewService(e.target.value)}
                      className=" rounded-md w-full h-[30px] px-2 shadow-md "
                    />
                  </div>
                  <button
                    onClick={() => handleCreateService()}
                    className="mt-12 ml-5"
                  >
                    <p className="text-xl text-white">✅</p>
                  </button>
                </div>
                <div className="w-3/4 m-auto h-[70%] rounded-md overflow-y-scroll bg-white shadow-lg">
                  <div className="flex flex-col justify-center items-center ">
                    {services?.map((service: any) => (
                      <div
                        key={service.id}
                        className={`w-full ${
                          service.id === idService ? "bg-dark-lavender" : ""
                        }
                         grid grid-cols-6 h-auto text-start pt-4 border-b-[1px] border-zinc-200 hover:bg-light-lavender transition-all duration-150 hover:bg-opacity-20`}
                      >
                        {editingServiceId === service.id ? (
                          <div className="col-start-1 col-end-4 w-full ml-5">
                            <input
                              type="text"
                              name="service"
                              id="service"
                              placeholder={service.name}
                              value={modifyNameService}
                              onChange={(e) =>
                                setModifyNameService(e.target.value)
                              }
                            />
                          </div>
                        ) : (
                          <button
                            className="col-start-1 col-end-5 pl-5 flex justify-start items-center"
                            onClick={() => setIdService(service.id)}
                          >
                            <p className="pb-4">{service.name}</p>
                          </button>
                        )}
                        {editingServiceId === service.id ? (
                          <div className="flex justify-end items-center mb-2">
                            <button
                              className="mr-5"
                              onClick={() =>
                                handleupdateServiceName(service.id)
                              }
                            >
                              <p className="text-xs text-white">✅</p>
                            </button>
                            <button
                              onClick={() => {
                                setModifyNameService("");
                                setEditingServiceId(null);
                              }}
                            >
                              <p className="text-xs text-white">❌</p>
                            </button>
                          </div>
                        ) : (
                          <div className="flex justify-around items-center col-start-5 col-end-6">
                            <input
                              type="checkbox"
                              name=""
                              id=""
                              className="w-4 h-4 mb-4 cursor-pointer"
                              checked={service?.isRenewable}
                              onChange={(e) =>
                                handleRenewable(service.id, e.target.checked)
                              }
                              onMouseEnter={(e) =>
                                handleMouseEnter("Mission reconductible", e)
                              }
                              onMouseLeave={handleMouseLeave}
                              disabled={service?.isUsed}
                            />
                            <button
                              className="flex justify-center items-center "
                              onClick={() => setEditingServiceId(service.id)}
                            >
                              <img
                                src={pen}
                                alt="stylo"
                                className="w-5 h-5 mb-4"
                              />
                            </button>
                          </div>
                        )}
                        <button
                          onClick={() => handleDeleteService(service.id)}
                          className="flex justify-center items-center col-start-6 col-end-7"
                        >
                          <img src={trash} alt="" className="w-5 h-5 mb-4" />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="w-1/2 flex flex-col h-full text-grey-trio font-BarlowCondensed text-lg">
                <div className="flex justify-center items-center w-3/4 m-auto">
                  <div className="w-full flex flex-col justify-start items-start mt-5">
                    <p className="text-center mr-5 font-bold text-grey-trio">
                      Nouvelle catégorie :
                    </p>
                    <input
                      type="text"
                      name="category"
                      id="category"
                      value={newCategory}
                      placeholder="Categorie"
                      onChange={(e) => setNewCategory(e.target.value)}
                      className=" rounded-md w-full h-[30px] px-2 shadow-md "
                    />
                  </div>
                  <button
                    onClick={() => handleCreateCategory()}
                    className="mt-12 ml-5"
                  >
                    <p className="text-xl text-white">✅</p>
                  </button>
                </div>
                <div className="w-3/4 m-auto h-[70%] rounded-md overflow-y-scroll bg-white shadow-lg">
                  <div className="flex flex-col justify-center items-center">
                    {categories?.map((category: any) => (
                      <div
                        key={category.id}
                        className="w-full grid grid-cols-5 h-auto pt-2 border-b-[1px] border-zinc-200 m-auto hover:bg-light-lavender hover:bg-opacity-20"
                      >
                        {editingCategoryId === category.id ? (
                          <div className="col-start-1 col-end-4 w-full ml-5">
                            <input
                              type="text"
                              name="service"
                              id="service"
                              placeholder={category.name}
                              value={modifyNameCategory}
                              onChange={(e) =>
                                setModifyNameCategory(e.target.value)
                              }
                            />
                          </div>
                        ) : (
                          <button
                            className="col-start-1 col-end-4 pl-5 flex justify-start items-center"
                            onClick={() => setIdCategory(category.id)}
                          >
                            <p className="pb-2 text-start">{category.name}</p>
                          </button>
                        )}
                        {editingCategoryId === category.id ? (
                          <div className="flex justify-end items-center ">
                            <button
                              className="mr-5"
                              onClick={() => handleUpdateCategory(category.id)}
                            >
                              <p className="text-xs text-white">✅</p>
                            </button>
                            <button
                              onClick={() => {
                                setModifyNameCategory("");
                                setEditingCategoryId(null);
                              }}
                            >
                              <p className="text-xs text-white">❌</p>
                            </button>
                          </div>
                        ) : (
                          <button
                            className="flex justify-center items-center col-start-4 col-end-5 mt-2"
                            onClick={() => setEditingCategoryId(category.id)}
                          >
                            <img
                              src={pen}
                              alt="stylo"
                              className="w-5 h-5 mb-4"
                            />
                          </button>
                        )}
                        <button
                          onClick={() => handleDeleteCategory(category.id)}
                          className="flex justify-center items-center col-start-5 col-end-6"
                        >
                          <img src={trash} alt="" className="w-5 h-5 mb-2" />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {tooltipState.content && (
              <div
                className="bg-nxm-darkBlue text-white p-2 rounded-md text-md absolute z-50 w-auto max-w-[200px] h-auto"
                style={{
                  top: tooltipState.position.y,
                  left: tooltipState.position.x,
                }}
              >
                {tooltipState.content}
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AdminCategories;
