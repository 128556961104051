import io from 'socket.io-client';
import { useNotifsStore } from '../../stores/Utils/notifs';
import logo from '../../assets/logos/logoX.png';

const backendUrl = import.meta.env.VITE_BACKEND_URL;

let socket: any;

// Fonction pour afficher une notification bureau
const displayNotifDesktop = (titre: string, message: string) => {
  if (Notification.permission === "granted") {
    new Notification(titre, {
      body: message,
      icon: logo,
    });
  } else if (Notification.permission !== "denied") {
    // Demande la permission à l'utilisateur
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        new Notification(titre, {
          body: message,
          icon: logo,
        });
      }
    });
  }
};

export const initSocket = (userId: string) => {
  socket = io(backendUrl as string);
    socket.on('connect', () => {
      socket.emit('joinRoom', userId);
    });

    socket.on("documentUploaded", (data: any) => {
      useNotifsStore.getState().addNotif(data);
      displayNotifDesktop("Nouveau document !", data.text || "Un document a été ajouté !");
    });

    socket.on("NewChatMessage", (data: any) => {
      useNotifsStore.getState().addNotif(data);
      displayNotifDesktop("Nouveau message !", data.text || "Un nouveau message a été ajouté !");
    })

    socket.on('connect_error', (err: any) => {
    });
};

export const leaveRoom = (userId: number) => {
  if (socket && socket.connected) {
    socket.emit('leaveRoom', userId);
    socket.disconnect();
  } else {
    console.log("Le socket n'est pas connecté.");
  }
};