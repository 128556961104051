import { useEffect } from "react";
import {
  dateFormat,
  fileNameFormat,
} from "../../../../services/Utils/functionService";
import {
  deleteFile,
  downloadDoc,
  openDoc,
} from "../../../../services/Api/documentService";
import { getDocRequest } from "../../../../services/Api/requestService";
import Eye from "../../../../assets/icons/eye.svg";
import Trash from "../../../../assets/icons/trash.svg";
import Download from "../../../../assets/icons/download.svg";
import Right from "../../../../assets/icons/chevron-right.svg";
import { useCustomerRequestsStore } from "../../../../stores/Cac/Customer/customerRequests";
import RequestDetails from "./RequestDetails";
import { useClickOutside } from "../../../../hooks/useClickOutside";
import { usePartnerRequestsStore2 } from "../../../../stores/Cac/Partner/partnerRequests";
import { showToastSuccess } from "../../../../services/Toasts/toasts";
import { DropZoneDocument } from "../../Partner/Utils/DropZoneDocument";
import RequestChat from "./RequestChat";
import { usePartnerRequestDetailsStore } from "../../../../stores/Cac/Partner/partnerRequestDetails";
import { DocumentRequestDetails } from "../../../../interfaces/documentInterface";

const RequestDocuments = () => {
  // Store
  const {
    requestIdCustomer,
    openDetailsCustomer,
    setOpenDetailsCustomer,
    request,
    setRequest,
    choiceTab,
    setChoiceTab,
  } = useCustomerRequestsStore();

  const { updateRequest, setUpdateRequest } = usePartnerRequestsStore2();
  const { setMessages, setMessage } = usePartnerRequestDetailsStore();

  // Hook
  useClickOutside([
    {
      elementId: "modal-doc-RequestDocuments",
      callback: () => setOpenDetailsCustomer(false),
    },
  ]);

  useEffect(() => {
    if (requestIdCustomer === 0) return;
    getDocRequest(requestIdCustomer)
      .then((data) => {
        if (!data) return;
        setRequest(data[0]);
      })
      .catch((error) => console.log(error));
  }, [requestIdCustomer, updateRequest]);

  const handleDeleteFile = async (id: number) => {
    await deleteFile(request.id, id);
    setUpdateRequest(!updateRequest);
    showToastSuccess("Le document a bien été supprimé");
  };

  useEffect(() => {
    if (!openDetailsCustomer) {
      setMessage("");
      setMessages([]);
    }
  }, [openDetailsCustomer]);

  return (
    <div
      className={`fixed right-0 top-0 h-full w-full lg:w-3/12 bg-dark-lavender2 shadow-2xl transition-all duration-300 ease-in-out transform z-50 min-w-[30%] text-xs md:text-base font-BarlowCondensed ${
        openDetailsCustomer ? "translate-x-0" : "translate-x-full"
      }`}
      id="modal-doc-RequestDocuments"
    >
      <div className="flex flex-col w-10/12 m-auto">
        <button
          type="button"
          className="lg:w-20 2xl:h-20 h-10 w-10 "
          onClick={() => {
            setOpenDetailsCustomer(false);
          }}
        >
          <img src={Right} alt="chevron right" />
        </button>
        <p className="text-white text-2xl font-K2D">
          Requête : {request?.fakeId}{" "}
        </p>
        <div className="flex items-center justify-between w-full">
          <div className="flex justify-center items-center">
            <p className="text-white text-md italic font-extralight mr-2">
              {request?.State?.state}
            </p>
            <div
              className={`w-8 h-4 border-[1px] rounded-md mr-2 justify-start
                        ${
                          request?.State?.state === "En Attente"
                            ? `border-filter-yellow bg-filter-yellow`
                            : null
                        }
                        ${
                          request?.State?.state === "Délivrée"
                            ? `border-filter-blue bg-filter-blue`
                            : null
                        }
                        ${
                          request?.State?.state === "Accepté"
                            ? `border-filter-green bg-filter-green`
                            : null
                        }
                        ${
                          request?.State?.state === "En retard"
                            ? `border-filter-orange bg-filter-orange`
                            : null
                        }
                        `}
            ></div>
          </div>
        </div>
      </div>
      <div className="w-10/12 flex pt-5 m-auto pb-2 text-lg">
        <button type="button" onClick={() => setChoiceTab("Documents")}>
          <p
            className={`text-white text-md mr-5 font-light ${
              choiceTab === "Documents" ? `underline font-semibold` : ``
            }`}
          >
            Documents{" "}
          </p>
        </button>
        <button type="button" onClick={() => setChoiceTab("Details")}>
          <p
            className={`text-white text-md mr-5 font-light ${
              choiceTab === "Details" ? `underline font-semibold` : ``
            }`}
          >
            Détails{" "}
          </p>
        </button>
        <button
          type="button"
          onClick={() => {
            setChoiceTab("Chat");
            setUpdateRequest(!updateRequest);
          }}
        >
          <p
            className={`text-white text-md mr-5 font-light ${
              choiceTab === "Chat" ? `underline font-semibold` : ``
            }`}
          >
            Chat{" "}
          </p>
        </button>
      </div>
      <hr className="w-10/12 m-auto pt-2 pb-2" />
      {choiceTab === "Documents" && request?.State?.state !== "Accepté" ? (
        <DropZoneDocument requestId={requestIdCustomer} />
      ) : null}
      {choiceTab === "Documents" ? (
        <div
          className={`w-10/12 h-[65%] bg-white m-auto rounded-md shadow-md overflow-y-scroll`}
        >
          {!request?.Documents || request?.Documents.length === 0 ? (
            <p className="text-center font-extralight pt-4">
              Il n'y a aucun documents déposés ...
            </p>
          ) : (
            <div className="flex flex-col w-full py-2">
              {request?.Documents?.map((document: DocumentRequestDetails) => (
                <div
                  key={document.id}
                  className="flex flex-col w-full m-auto pt-2 hover:bg-gray-200 "
                >
                  <div className="grid grid-cols-3 w-11/12">
                    <div className="text-slate-600 text-md font-semibold col-start-1 col-end-3 pl-5 flex overflow-hidden whitespace-nowrap text-overflow-ellipsis pr-4 mr-2">
                      {fileNameFormat(document?.filename)}
                    </div>
                    <div className="grid grid-cols-3 col-start-3 col-end-4">
                      {document.type === "xlsx" || document.type === "zip" ? (
                        <div className="col-start-1 col-end-2"></div>
                      ) : (
                        <button
                          type="button"
                          onClick={() => {
                            openDoc(request?.id, document?.id);
                          }}
                        >
                          <img
                            src={Eye}
                            alt="visualisation"
                            className="col-start-1 col-end-2"
                          />
                        </button>
                      )}
                      <button
                        type="button"
                        onClick={() => {
                          downloadDoc(
                            request?.id,
                            document?.id,
                            document?.filename
                          );
                        }}
                      >
                        <img
                          src={Download}
                          alt="visualisation"
                          className="col-start-2 col-end-3"
                        />
                      </button>
                      {choiceTab === "Documents" &&
                      request?.State?.state === "Accepté" ? (
                        <></>
                      ) : (
                        <button
                          type="button"
                          onClick={() => handleDeleteFile(document?.id)}
                        >
                          <img
                            className="col-start-3 col-end-4"
                            src={Trash}
                            alt="trash"
                          />
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center justify-center py-2">
                    <p className="text-slate-600 text-md font-extralight pr-5">
                      Date upload : {dateFormat(document?.createdAt)}
                    </p>
                    <p className="text-slate-600 text-md font-extralight">
                      Type : {document?.type}
                    </p>
                  </div>
                  <hr className="w-10/12 m-auto py-2" />
                </div>
              ))}
            </div>
          )}
        </div>
      ) : null}
      {choiceTab === "Details" ? <RequestDetails /> : null}
      {choiceTab === "Chat" ? <RequestChat /> : null}
    </div>
  );
};

export default RequestDocuments;
