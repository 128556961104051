import React, { useState } from "react";
import { resetPasswordRequest } from "../../services/Api/loginService";
import { useConnexionStore } from "../../stores/Layout/connexion";
import { showToastError } from "../../services/Toasts/toasts";

const ModalForgotPassword = () => {
  // Store
  const { showModalForgotPassword, setShowModalForgotPassword } =
    useConnexionStore();
  const [email, setEmail] = useState("");
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    resetPasswordRequest(email)
      .then(() => setShowModalForgotPassword(false))
      .catch((err) => {
        if (err.response.data === "googleAccount") {
          showToastError(
            "Vous ne pouvez pas réinitialiser le mot de passe de votre compte Google. Merci d'utiliser la connexion avec Google."
          );
        } else if (err.response.data === "officeAccount") {
          showToastError(
            "Vous ne pouvez pas réinitialiser le mot de passe de votre compte Office. Merci d'utiliser la connexion avec Office."
          );
        } else if (err.response.data === "accountNotCreated") {
          showToastError("Vous n'avez pas créé de compte");
        } else if (err.response.data === "AccountNotFound") {
          showToastError("Aucun compte n'a été trouvé avec cette adresse mail");
        }
      });
  };

  return (
    <>
      {showModalForgotPassword ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none text-nxm-darkGray">
            <div className="relative w-auto md:w-4/12 my-6 mx-auto max-w-3xl xl:min-w-[25%]">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-center p-4  border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-xl 2xl:text-3xl font-semibold text-center text-grey-trio font-K2D">
                    Mot de passe oublié ?
                  </h3>
                </div>
                {/*body*/}
                <div className="w-full py-5 text-grey-trio flex flex-col items-start justify-center">
                  <div className="w-full ">
                    <div className="w-11/12 m-auto flex flex-col justify-center items-center text-base pt-1">
                      <label htmlFor="email" className="leading-relaxed">
                        Veuillez inscrire votre adresse mail :
                      </label>
                      <input
                        type="email"
                        className="border-[1px] rounded-md border-dark-lavender w-80 h-10 p-1 focus:outline-none "
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                        onChange={(e) => setEmail(e.target.value)}
                        minLength={10}
                        maxLength={200}
                        name="email"
                        id="email"
                        placeholder="votre-email@exemple.com"
                        onKeyDown={handleKeyDown}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end 2xl:p-4 p-2 border-t border-solid border-slate-200 rounded-b font-BarlowCondensed">
                  <button
                    className="text-red-300 background-transparent hover:text-red-500 font-bold uppercase px-6 py-2 2xl:text-lg text-base  outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModalForgotPassword(false)}
                  >
                    ANNULER
                  </button>
                  <button
                    className="text-white bg-gradient-to-r from-light-lavender via-light-lavender to-dark-lavender hover:bg-gradient-to-br font-medium rounded-lg 2xl:text-lg text-base  px-5 py-2.5 text-center me-2 uppercase  shadow hover:shadow-lg outline-none  ease-linear transition-all duration-150"
                    type="submit"
                    onClick={(
                      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => {
                      handleSubmit(event);
                    }}
                  >
                    ENVOYER
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default ModalForgotPassword;
