import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { TokenProvider } from "./context/tokenContext";
import App from "./App";
import "../src/css/App.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

const googleId = import.meta.env.VITE_GOOGLE_CLIENT_ID;
const officeId = import.meta.env.VITE_OFFICE_CLIENT_ID;
const redirectUri = import.meta.env.VITE_OFFICE_REDIRECT_URI;

const msalConfig = {
  auth: {
    clientId: officeId,
    authority: `https://login.microsoftonline.com/common`,
    redirectUri: redirectUri,
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <GoogleOAuthProvider clientId={googleId}>
    <MsalProvider instance={msalInstance}>
      <React.StrictMode>
        <BrowserRouter>
          <TokenProvider>
            <App />
          </TokenProvider>
        </BrowserRouter>
      </React.StrictMode>
    </MsalProvider>
  </GoogleOAuthProvider>
);
