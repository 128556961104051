import { ChangeEvent, useEffect, useState } from "react";
import { dateFormat } from "../../../../services/Utils/functionService";
import {
  getCustomersRequest,
  patchRequest,
} from "../../../../services/Api/requestService";
import { usePartnerRequestDetailsStore } from "../../../../stores/Cac/Partner/partnerRequestDetails";
import { usePartnerRequestsStore2 } from "../../../../stores/Cac/Partner/partnerRequests";
import SelectDate from "../Utils/SelectDate";
import { useUtilsStore } from "../../../../stores/Utils/selects";
import {
  showToastError,
  showToastSuccess,
} from "../../../../services/Toasts/toasts";
import { getUsersCustomerToMission } from "../../../../services/Api/customerService";
import { useParams } from "react-router-dom";

const RequestDetails = () => {
  // Store
  const {
    request,
    comment,
    setComment,
    clientsAssign,
    setClientsAssign,
    clientsAssignToMission,
    setClientsAssignToMission,
    openCommentary,
    setOpenCommentary,
    checkboxValues,
    setCheckboxValues,
  } = usePartnerRequestDetailsStore();
  const { requestId, updateRequest, setUpdateRequest } =
    usePartnerRequestsStore2();
  const { startDate } = useUtilsStore();
  const { idCustomer, idMission } = useParams();
  // State
  const [compteur, setCompteur] = useState<number>(255);

  useEffect(() => {
    if (requestId !== 0) {
      getCustomersRequest(requestId)
        .then((data) => {
          setClientsAssign(data);

          getUsersCustomerToMission(Number(idCustomer), idMission)
            .then((users) => {
              setClientsAssignToMission(users);

              if (users.length === 0) {
                return;
              }
              const clientCheckboxValues: { [key: number]: boolean } = {};
              users?.forEach((user: any) => {
                clientCheckboxValues[user.id] = data.some(
                  (client: any) => client.id === user.id
                );
              });
              setCheckboxValues(clientCheckboxValues);
              setComment(request?.Commentaries?.[0]?.text || "");
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    }
  }, [requestId, idCustomer, idMission, updateRequest]);

  // This is for block commentary input when compteur is 0
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const nouveauTexte = e.target.value;
    const nouveauCompteur = 255 - nouveauTexte.length;
    setComment(nouveauTexte);
    setCompteur(nouveauCompteur);
  };

  const handleButtonClick = async () => {
    const dataUpdate = {
      dueDate: startDate,
      idUsers: Object.entries(checkboxValues)
        .filter(([_, value]) => value)
        .map(([key]) => Number(key)),
      description: comment,
    };

    patchRequest(requestId, dataUpdate)
      .then(() => {
        setOpenCommentary(false);
        showToastSuccess("Les modifications ont bien été prises en compte");
        setUpdateRequest(!updateRequest);
        setComment("");
      })
      .catch((error) => {
        showToastError("Une erreur est survenue lors de la modification");
        console.log(error);
      });
  };

  const handleCheckboxChange = (value: number, checked: boolean) => {
    setCheckboxValues({ ...checkboxValues, [value]: checked });
  };

  useEffect(() => {
    if (openCommentary) {
      setComment(request?.description || "");
    }
  }, [openCommentary]);

  return (
    <div className="w-10/12  m-auto rounded-md flex flex-col text-white font-BarlowCondensed text-lg ">
      <div className="grid grid-cols-3 justify-start items-start">
        <p className="font-extralight col-start-1 col-end-2 text-md text-white pr-2 py-2">
          Id :
        </p>
        <p className="text-md col-start-2 col-end-4 py-2">{request?.fakeId}</p>
      </div>
      <div className="grid grid-cols-3 justify-start items-start">
        <p className="font-extralight col-start-1 col-end-2 text-md text-white pr-2 py-2">
          Catégorie :
        </p>
        <p className="text-md col-start-2 col-end-4 py-2">
          {request?.CategoryDocRequest?.name}
        </p>
      </div>
      <div className="grid grid-cols-3 justify-start items-start">
        <p className="font-extralight col-start-1 col-end-2 text-md text-white pr-2 py-2">
          Demandeur :
        </p>
        <p className="text-md col-start-2 col-end-4 py-2">
          {request?.User?.fullName}
        </p>
      </div>
      <div className="grid grid-cols-3 justify-start items-start">
        <p className="font-extralight col-start-1 col-end-2 text-md text-white pr-2 py-2">
          Date création :
        </p>
        <p className="text-md col-start-2 col-end-4 py-2">
          {dateFormat(request?.createdAt)}
        </p>
      </div>
      <div className="grid grid-cols-3 justify-start items-start">
        <p className="font-extralight text-md text-white col-start-1 col-end-2 pr-2 py-2">
          Date limite :
        </p>
        {!openCommentary ? (
          <div className="text-md col-start-2 col-end-4 py-2">
            <p className="">{dateFormat(request?.dueDate)}</p>
          </div>
        ) : (
          <div className="mt-2 text-nxm-darkGray z-50">
            <SelectDate />
          </div>
        )}
      </div>
      <div className="grid grid-cols-3 justify-start items-start">
        <p className="font-extralight col-start-1 col-end-2 text-md text-white pr-2 py-2">
          Etat :
        </p>
        <p className="col-start-2 col-end-4 py-2 text-md">
          {request?.State?.state}
        </p>
      </div>
      <div className="grid grid-cols-3 justify-start items-start">
        <p className="font-extralight col-start-1 col-end-2 text-md text-white py-2">
          Description :
        </p>
        <p
          className={
            openCommentary
              ? "hidden"
              : "col-start-2 col-end-4 text-md pt-2 h-44 overscroll-auto whitespace-pre-line"
          }
        >
          {request?.description || "Pas de commentaire"}
        </p>
        <div
          className={
            openCommentary
              ? "w-full col-start-2 col-end-4 justify-start items-start h-auto"
              : "hidden"
          }
        >
          <div className="flex flex-col justify-end items-end ">
            <textarea
              name="comment"
              onChange={(e: any) => handleChange(e)}
              id="comment"
              disabled={compteur === 0}
              className="w-full rounded h-28 text-left self-start p-2 text-nxm-darkGray 2xl:mt-5 mt-2 text-sm"
              value={comment}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-3 text-md mt-auto relative w-full z-30">
        <p className="col-start-1 col-end-2 row-start-1 row-end-2 font-extralight text-md text-white pr-2 2xl:mt-5 mt-2 ">
          Clients :
        </p>

        {openCommentary ? (
          <div className="col-start-2 col-end-4 w-full bg-white rounded-md mt-4 text-nxm-darkGray h-40 border-b-[1px] border-slate-200 flex flex-col items-start justify-start text-xs sm:text-base overflow-y-scroll">
            {clientsAssignToMission.length > 0 ? (
              clientsAssignToMission?.map((user) => (
                <div
                  className="flex justify-start items-center p-4 w-full"
                  key={user?.id}
                >
                  <input
                    type="checkbox"
                    checked={checkboxValues[user?.id] || false}
                    onChange={() => {
                      handleCheckboxChange(user?.id, !checkboxValues[user?.id]);
                    }}
                    className="mr-4 "
                  />
                  <label>
                    {user.fullName !== "" ? (
                      <div className="flex flex-col justify-start items-start">
                        <p className="font-thin italic text-sm">
                          {user.fullName}
                        </p>
                        <p className="font-thin italic text-sm text-start">
                          {user.eMail}
                        </p>
                      </div>
                    ) : (
                      <div className="flex flex-col justify-center items-center">
                        <p>{user.eMail}</p>
                        <p className="font-thin italic text-sm text-start">
                          compte pas encore créé
                        </p>
                      </div>
                    )}
                  </label>
                </div>
              ))
            ) : (
              <p className="italic">Aucun utilisateur</p>
            )}
          </div>
        ) : (
          <div className="col-start-2 col-end-4 w-full bg-white rounded-md mt-4 text-nxm-darkGray h-40 border-b-[1px] border-slate-200 flex flex-col items-start justify-start text-xs sm:text-base overflow-y-scroll">
            {clientsAssign?.map((user: any) => {
              return (
                <div
                  key={user?.id}
                  className="flex justify-start items-center p-4 w-full"
                >
                  {user.fullName !== "" ? (
                    <div className="flex flex-col justify-start items-start">
                      <p className="font-thin italic text-sm">
                        {user.fullName}
                      </p>
                      <p className="font-thin italic text-sm">{user.eMail}</p>
                    </div>
                  ) : (
                    <div className="flex flex-col justify-center items-center">
                      <p>{user.eMail}</p>
                      <p className="font-thin italic text-sm">
                        compte pas encore créé
                      </p>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className="w-full flex justify-end items-end 2xl:mt-5 mt-2">
        {openCommentary && (
          <button
            className="text-white bg-gradient-to-r from-light-lavender via-light-lavender to-dark-lavender hover:bg-gradient-to-br font-medium rounded-lg 2xl:text-lg text-base  px-5 py-2.5 text-center me-2 uppercase  shadow hover:shadow-lg outline-none  ease-linear transition-all duration-150"
            type="button"
            onClick={handleButtonClick}
          >
            Valider les modifications
          </button>
        )}
      </div>
      <div></div>
    </div>
  );
};

export default RequestDetails;
