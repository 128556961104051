import { useEffect } from "react";
import { uploadLogo } from "../../../services/Api/superadminService";
import {
  showToastError,
  showToastSuccess,
} from "../../../services/Toasts/toasts";
import { useSuperAdminCreateUserPartnerStore } from "../../../stores/superadmin/createUser";
import { getLogo } from "../../../services/Api/partnerService";

const AddLogo = ({ idPartner, closeModal, namePartner }: any) => {
  const { imgDownload, setImgDownload, forceCall, setForceCall } =
    useSuperAdminCreateUserPartnerStore();

  useEffect(() => {
    getLogo(idPartner)
      .then((res) => {
        setImgDownload(res.data.base64);
      })
      .catch((error) => console.log(error));
  }, [idPartner, forceCall]);

  const handleUploadLogo = (logo: any) => {
    // handle upload logo
    const formData = new FormData();
    formData.append("logo", logo);

    uploadLogo(idPartner, formData)
      .then(() => {
        showToastSuccess("Le logo a bien été ajouté !");
        setImgDownload("");
        setForceCall(!forceCall);
      })
      .catch(() => showToastError("Une erreur est survenue."));
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">
        <div className="relative w-auto md:w-1/4 my-6 mx-auto max-w-3xl min-w-[50%]">
          {/*content*/}
          <div className="border-0 rounded-t-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-center justify-center p-5 border-b border-solid border-slate-200 font-K2D">
              <h3 className="text-xl sm:text-3xl font-semibold text-center text-nxm-darkGray">
                <p>
                  Ajouter un logo pour{" "}
                  <span className="font-extrabold">{namePartner}</span>
                </p>
              </h3>
            </div>
            {/*body*/}
            <div className="w-10/12 m-auto flex items-center justify-between h-44 ">
              <input
                type="file"
                name="logo"
                id="logo"
                onChange={(e) => {
                  if (e.target.files && e.target.files.length > 0) {
                    handleUploadLogo(e.target.files[0]);
                  }
                }}
                className="cursor-pointer flex h-9 mr-2 w-4/12 rounded-md border border-input bg-background px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-foreground file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
              />
              {imgDownload ? (
                <img
                  src={`data:image/png;base64,${imgDownload}`}
                  alt="Logo"
                  className="w-44 h-32 object-contain"
                />
              ) : (
                <p>Aucun logo...</p>
              )}
            </div>
          </div>
          {/*footer*/}
          <div className="flex items-center justify-end py-4 border-t border-solid border-slate-200 rounded-b-lg bg-white font-BarlowCondensed">
            <button
              className="text-red-500 background-transparent font-bold uppercase px-6 py-2 2xl:text-lg text-base  outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => {
                closeModal();
                setImgDownload("");
              }}
            >
              Annuler
            </button>
            <button
              className="text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg 2xl:text-lg text-base  px-5 py-2.5 text-center me-2 uppercase  shadow hover:shadow-lg outline-none  ease-linear transition-all duration-150"
              type="button"
              onClick={() => closeModal()}
            >
              Valider
            </button>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default AddLogo;
