import { useNavigate } from "react-router-dom";
import { useSettingsStore } from "../../../../stores/Utils/settings";
import { useTokenContext } from "../../../../context/tokenContext";
import { useClickOutside } from "../../../../hooks/useClickOutside";
import "../../../../css/animations.css";

const Settings = () => {
  const { setShowSettings, showSettings } = useSettingsStore();
  const { isSuperAdmin } = useTokenContext();
  const navigate = useNavigate();

  // Hook
  useClickOutside([
    {
      elementId: "modal-Settings",
      callback: () => setShowSettings(false),
    },
  ]);

  return (
    <div className="">
      {showSettings && (
        <div
          className={`absolute top-64 left-14 z-40 right-0 shadow-lg p-4 rounded-md w-[300px] xl:w-[400px] h-[150px] ${
            isSuperAdmin === 1 ? " overflow-y-scroll" : ""
          } bg-white`}
          id="modal-Settings"
        >
          <p className="text-center font-BarlowCondensed text-lg w-full bg-yellow-trio rounded-md ">
            Parametrage :
          </p>
          {isSuperAdmin === 1 ? (
            <button
              className="flex justify-start items-center mt-4 w-full learn-more"
              onClick={() => {
                setShowSettings(false);
                navigate("/superadmin/dashboard");
              }}
            >
              <span className="circle" aria-hidden="true">
                <span className="icon arrow"></span>
              </span>
              <p className="text-md text-grey-trio button-text">Super admin</p>
            </button>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default Settings;
