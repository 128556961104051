import { create } from "zustand";
 
type ClientFileState = {
    clientFiles: any[];
    setClientFiles: (clientFiles: any[]) => void;
}

export const useClientFileStore = create<ClientFileState>((set) => ({
    clientFiles: [],
    setClientFiles: (clientFiles) => set({ clientFiles }),
}));