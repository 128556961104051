import React from "react";
import useModal from "../../../hooks/useModal";
import { sendBackMail } from "../../../services/Api/userService";
import { usePartnerStore } from "../../../stores/admin/users";
import { showToastSuccess } from "../../../services/Toasts/toasts";
import pen from "../../../assets/icons/edit.svg";
import send from "../../../assets/icons/send.svg";
import UpdateUserPartner from "../Modales/UpdateUserPartner";
import DeleteUser from "../Modales/DeleteUser";
import trash from "../../../assets/icons/trash.svg";
const PartnerAdminTable = () => {
  // Store
  const { usersPartner } = usePartnerStore();
  // Hook
  const { modalContent, isModalOpen, openModal, closeModal } = useModal();

  const handleSendBack = (idUser: number) => {
    sendBackMail(idUser)
      .then(() => showToastSuccess("L'email a bien été envoyé à l'utilisateur"))
      .catch((err) => console.log(err));
  };

  return (
    <div className="h-full w-full flex">
      {isModalOpen ? modalContent : null}
      <div className="flex flex-col w-full h-full font-BarlowCondensed ">
        <div className="w-full m-auto py-2 grid grid-cols-9 grid-rows-1 text-xl border-b-[1px] border-zinc-100 text-grey-trio bg-white">
          <div className="col-start-1 col-end-3 font-bold">Nom</div>
          <div className="col-start-3 col-end-4 font-bold">Prénom</div>
          <div className="col-start-4 col-end-6 font-bold">Email</div>
          <div className="col-start-6 col-end-7 font-bold">Titre</div>
          <div className="col-start-7 col-end-8 font-bold">Rôle</div>
          <div className="col-start-8 col-end-9 font-bold">Accès</div>
          <div className="col-start-9 col-end-10 font-bold"></div>
        </div>
        <div className="w-full h-full overflow-y-scroll bg-white mb-5">
          {usersPartner?.map((user: any) => (
            <div
              key={user?.id}
              className={
                "w-full hover:bg-light-lavender transition-all duration-150 hover:bg-opacity-20 m-auto grid grid-cols-9 2xl:text-xl text-md font-light text-lg h-auto py-2"
              }
            >
              <div className="col-start-1 col-end-3 flex items-center">
                <p>{user?.lastName}</p>
              </div>
              <div className="col-start-3 col-end-4 flex items-center pl-1">
                <p>{user?.firstName}</p>
              </div>
              <div className="col-start-4 col-end-6 flex items-center pl-2">
                <p>{user?.eMail}</p>
              </div>
              <div className="col-start-6 col-end-7 flex items-center pl-2">
                <p>{user?.titre}</p>
              </div>
              <div className="col-start-7 col-end-8 flex items-center pl-3">
                <p>{user?.role?.role}</p>
              </div>
              <div className="col-start-8 col-end-9 flex items-center pl-3">
                <button onClick={() => handleSendBack(user.id)}>
                  <img src={send} alt="icon envoyer" />
                </button>
              </div>
              <div className="col-start-9 col-end-10 flex justify-start items-center">
                <button
                  type="button"
                  className="col-start-1 col-end-2 flex items-center justify-start mr-3"
                  onClick={() => {
                    openModal(
                      <UpdateUserPartner
                        id={user?.id}
                        closeModal={closeModal}
                      />
                    );
                  }}
                >
                  <img className="block h-5 w-5" src={pen} alt="stylo" />
                </button>
                <button
                  type="button"
                  className="col-start-1 col-end-2 flex items-center justify-start mr-3"
                  onClick={() => {
                    openModal(
                      <DeleteUser idUser={user?.id} closeModal={closeModal} />
                    );
                  }}
                >
                  <img className="block h-5 w-5" src={trash} alt="poubelle" />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(PartnerAdminTable);
