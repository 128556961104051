import { create } from "zustand";

type profil = {
    firstName: string;
    setFirstName: (firstname: string) => void;

    lastName: string;
    setLastName: (lastName: string) => void;

    email: string;
    setEmail: (email: string) => void;

    phone: string;
    setPhone: (phone: string) => void;

    title: string;
    setTitle: (title: string) => void;

    lastPassword: string;
    setLastPassword: (lastPassword: string) => void;

    newPassword: string;
    setNewPassword: (newPassword: string) => void;

    update: boolean;
    setUpdate: (update: boolean) => void;

    reset: () => void;
}

export const useProfil = create<profil>((set) => ({ 
    firstName: "",
    setFirstName: (firstName) => set({firstName}),

    lastName:"",
    setLastName: (lastName) => set({lastName}),

    email: "",
    setEmail: (email) => set({email}),

    phone: "",
    setPhone: (phone) => set({phone}),

    title: "",
    setTitle: (title) => set({title}),

    lastPassword: "",
    setLastPassword: (lastPassword) => set({lastPassword}),

    newPassword: "",
    setNewPassword: (newPassword) =>  set({newPassword}),

    update: false,
    setUpdate: (update) => set({update}),

    reset: () => ({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        newPassword: "",
        lastPassword: "",
    })
}))