import { useParams } from "react-router-dom";
import { deletePartnerAdmin } from "../../../services/Api/adminService";
import { useServiceStore } from "../../../stores/admin/service";
import { showToastSuccess } from "../../../services/Toasts/toasts";

const DeleteUser = ({ closeModal, idUser }: any) => {
  const { idPartner } = useParams();
  const { update, setUpdate } = useServiceStore();

  const handleDeleteUser = () => {
    deletePartnerAdmin(Number(idPartner), Number(idUser))
      .then(() => {
        setUpdate(!update);
        showToastSuccess("L'utilisateur a bien été supprimé");
        closeModal();
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">
        <div className="relative w-auto md:w-1/4 my-6 mx-auto max-w-3xl min-w-[40%]">
          {/*content*/}
          <div className="border-0 relative flex flex-col w-full bg-white outline-none focus:outline-none rounded-t">
            {/*header*/}
            <div className="flex items-center justify-center p-4 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-xl 2xl:text-3xl font-semibold text-center font-K2D text-nxm-darkGray ">
                Supprimer un utilisateur
              </h3>
            </div>
            {/*body*/}
            <div className="w-10/12 m-auto flex flex-col items-center justify-center">
              <p className="my-5">
                Etes vous sur de bien vouloir supprimer l'utilisateur ?
              </p>
            </div>
          </div>
          {/*footer*/}
          <div className="flex items-center justify-end 2xl:p-4 p-2 border-t border-solid border-slate-200 rounded-b bg-white font-BarlowCondensed">
            <button
              className="text-red-300 background-transparent hover:text-red-500 font-bold uppercase px-6 py-2 2xl:text-lg text-base  outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => {
                closeModal();
              }}
            >
              Annuler
            </button>
            <button
              className="text-white bg-gradient-to-r from-light-lavender via-light-lavender to-dark-lavender hover:bg-gradient-to-br font-medium rounded-lg 2xl:text-lg text-base  px-5 py-2.5 text-center me-2 uppercase  shadow hover:shadow-lg outline-none  ease-linear transition-all duration-150"
              type="button"
              onClick={() => {
                handleDeleteUser();
              }}
            >
              Supprimer
            </button>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default DeleteUser;
