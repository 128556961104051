import { useTokenContext } from "../../context/tokenContext";
import { useConnexionStore } from "../../stores/Layout/connexion";
import { useMfaStore } from "../../stores/Utils/mfa";
import { redirection } from "../../services/Utils/functionService";
import { verifyMfa } from "../../services/Api/userService";
import { useNavigate } from "react-router-dom";
import AuthCode from "react-auth-code-input";
import useTooltip from "../../hooks/useTooltip";
import { showToastError } from "../../services/Toasts/toasts";
import { ToastContainer } from "react-toastify";

const ModalMfa = () => {
  const { password, email } = useConnexionStore();
  const navigate = useNavigate();
  const [tooltipState, handleMouseEnter, handleMouseLeave] = useTooltip();

  const { showModalCodeMFA, setShowModalCodeMFA, codeMfa, setCodeMfa } =
    useMfaStore();
  const {
    setToken,
    setFirstname,
    setLastname,
    setEmailUser,
    setUserId,
    setMfa,
    setUtc,
    setUtp,
    setIsAdmin,
    setIsSuperAdmin,
    setAccountType,
  } = useTokenContext();

  const handleCheckMfa = async (e: any) => {
    e.preventDefault();
    try {
      await verifyMfa(codeMfa, email, password)
        .then((res) => {
          setToken(1);
          setShowModalCodeMFA(false);
          setFirstname(res?.data?.firstName);
          localStorage.setItem("firstName", res?.data?.firstName);
          setLastname(res?.data?.lastName);
          localStorage.setItem("lastName", res?.data?.lastName);
          setEmailUser(res?.data?.eMail);
          localStorage.setItem("eMail", res?.data?.eMail);
          setUserId(res?.data?.id);
          localStorage.setItem("id", res?.data?.id);
          setMfa(res?.data?.mfa);
          const mfa = res?.data?.mfa;
          localStorage.setItem("mfa", mfa);
          const utc = res?.data?.utc;
          localStorage.setItem("utc", utc);
          setUtc(utc);
          const utp = res?.data?.utp;
          localStorage.setItem("utp", utp);
          setUtp(utp);
          const isAdmin = res?.data?.isAdmin;
          localStorage.setItem("utp", isAdmin);
          setIsAdmin(isAdmin);
          const isSuperAdmin = res?.data?.isSuperAdmin;
          localStorage.setItem("isSuperAdmin", isSuperAdmin);
          setIsSuperAdmin(isSuperAdmin);
          const accountType = res?.data?.accountType;
          localStorage.setItem("accountType", accountType);
          setAccountType(accountType);
          setShowModalCodeMFA(false);
          redirection(location, navigate, isSuperAdmin);
        })
        .catch((err) => {
          console.log(err);
          if (err) showToastError("Code MFA incorrect");
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleCheckMfa(e);
    }
  };

  return showModalCodeMFA ? (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none min-w-full">
        <ToastContainer position="top-center" />
        <div className="relative w-auto md:w-1/4 my-6 mx-auto max-w-3xl min-w-[35%]">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-center p-5 border-b border-solid border-slate-200 rounded-t relative">
              <h3 className="text-base md:text-3xl font-semibold text-center">
                Authentification MFA
              </h3>
              <div
                onMouseLeave={handleMouseLeave}
                onMouseEnter={(e) =>
                  handleMouseEnter(
                    `En cas de perte de votre MFA, veuillez contacter le service client.`,
                    e
                  )
                }
                className="absolute border-2 border-gray-700 rounded-full text-gray-700 w-6 h-6 flex justify-center items-center top-3 right-3 hover:bg-gray-100 hover:cursor-pointer"
              >
                ?
              </div>
              {tooltipState.content && (
                <div className=" bg-gray-600 text-white p-2 rounded-md text-sm absolute z-50 w-auto max-w-[200px] h-auto top-8 right-8">
                  {tooltipState.content}
                </div>
              )}
            </div>
            {/*body*/}
            <div
              className="text-sm md:text-base text-center p-5 flex flex-col justify-center items-center "
              onKeyDown={handleKeyDown}
            >
              <p className="w-10/12 m-auto">
                Veuillez saisir le code renseigner sur votre application
                authenticateur, installé sur votre smartphone.
              </p>
              <AuthCode
                allowedCharacters="numeric"
                length={6}
                onChange={(value) => setCodeMfa(value)}
                containerClassName="my-4"
                inputClassName="border-[1px] rounded-md border-gray-200 w-10 h-12 mr-2 text-center shadow-md mb-4"
              />
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-4 border-t border-solid border-slate-200 rounded-b font-BarlowCondensed">
              <button
                className="text-red-300 background-transparent hover:text-red-500 font-bold uppercase px-6 py-2 2xl:text-lg text-base  outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShowModalCodeMFA(false)}
              >
                Annuler
              </button>
              <button
                className="text-white bg-gradient-to-r from-light-lavender via-light-lavender to-dark-lavender hover:bg-gradient-to-br font-medium rounded-lg 2xl:text-lg text-base  px-5 py-2.5 text-center me-2 uppercase  shadow hover:shadow-lg outline-none  ease-linear transition-all duration-150"
                type="button"
                onClick={(e) => {
                  handleCheckMfa(e);
                }}
              >
                Valider
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  ) : null;
};

export default ModalMfa;
