import { useEffect } from "react";

// ******************** Click Outside for close modal ******************** //
export const useClickOutside = (
  modals: Array<{ elementId: string; callback: Function }>
) => {
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      modals.forEach(({ elementId, callback }) => {
        const modal = document.getElementById(elementId);
        if (modal && !modal.contains(event.target)) {
          callback();
        }
      });
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modals]);
};
