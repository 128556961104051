import { useEffect } from "react";
import StatusRequest from "../../../components/Cac/Customer/Request/StatusRequest";
import CustomerMissionTable from "../../../components/Cac/Customer/Mission/CustomerMissionTable";
import { useCustomerMissionStore } from "../../../stores/Cac/Customer/customerMission";
import { getMissions } from "../../../services/Api/customerService";
import { useNavigate, useParams } from "react-router-dom";
import Return from "../../../assets/icons/return.svg";
import SideBar from "../../../components/Layout/SideBar";
import Header from "../../../components/Layout/Header";
import Footer from "../../../components/Layout/Footer";

const CustomerHome = () => {
  const { setMissionsClient } = useCustomerMissionStore();
  // Hooks
  const navigate = useNavigate();
  const { idCustomer } = useParams();

  useEffect(() => {
    getMissions(Number(idCustomer))
      .then((data) => setMissionsClient(data?.missions))
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="w-screen h-screen flex flex-col justify-start items-start overflow-hidden bg-white">
      <Header />
      <div className="w-full flex justify-start items-start">
        <SideBar />
        <div className="flex flex-col justify-start items-center w-11/12 h-screen m-auto">
          <div className="w-11/12 flex justify-start sm:justify-between items-center 2xl:my-5 my-2">
            <h2 className="my-2 text-nxm-darkGray font-light 2xl:text-3xl text-xl mr-5 ml-1 font-K2D ">
              Vos missions en cours :
            </h2>
          </div>
          <div className="hidden md:flex justify-between items-center w-11/12 pt-2 2xl:mb-10 mb-5">
            <button
              type="button"
              className="flex"
              onClick={() => navigate("/dashboard")}
              title="Retour"
            >
              <img src={Return} alt="return" />
            </button>
            <div className="flex sm:flex-row flex-col items-center justify-center">
              <StatusRequest />
            </div>
          </div>
          <div className="w-11/12 h-[70%] rounded-md bg-white mb-20">
            <CustomerMissionTable />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CustomerHome;
